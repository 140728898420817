import styled from "styled-components";

const Checkbox = styled.input`
  width: 32px;
  height: 32px;

  border: 2px solid ${({ theme }) => theme.colors.palette.gray[50]};
  border-radius: 100%;

  &:checked {
    background: url("/circled-check-full.svg") no-repeat center;
    border: none;
  }
`;

Checkbox.defaultProps = { type: "checkbox", role: "checkbox" };

export default Checkbox;
