import { ReactComponent as ArrowSvg } from "/public/landing/how-to-start/arrow.svg";
import styled from "styled-components";
import { Step } from "./StartStepsData";

interface Props {
  index: number;
  step: Step;
}

const StartStep = ({ index, step: { title, subtitle } }: Props) => (
  <Container>
    <BulletContainer>
      <Bullet>{index}</Bullet>
      <Arrows>
        <ArrowSvg />
        <ArrowSvg />
        <ArrowSvg />
      </Arrows>
    </BulletContainer>
    <TextContainer>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </TextContainer>
  </Container>
);

const Arrows = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;

  padding: 8px 0;
`;

const Container = styled.div`
  display: flex;

  &:last-child {
    ${Arrows} {
      visibility: hidden;
    }
  }
`;

const BulletContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Bullet = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 28px;

  font-size: 12px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.palette.blue["500"]};

  background-color: ${(props) => props.theme.colors.palette.blue["50"]};
  border-radius: 8px;
`;

const TextContainer = styled.div`
  margin-left: 16px;
  padding-bottom: 16px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
`;
const SubTitle = styled.div`
  margin-top: 4px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${(props) => props.theme.colors.palette.gray["500"]};
`;

export default StartStep;
