import styled from "styled-components";

const Heading2 = styled.h1`
  font-size: 16px;
  font-weight: 900;
  line-height: 128%;
  color: ${(props) => props.theme.colors.static.inverted};
`;

export default Heading2;
