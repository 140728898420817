import { useAtom } from "jotai";
import { atomWithStorage, RESET } from "jotai/utils";
import { z } from "zod";
import { stageAddressFormSchema } from "../stages/StageAddress";
import { stageCertificationsFormSchema } from "../stages/StageCertifications";
import { stageNameFormSchema } from "../stages/StageName";
import { stagePhoneNumberFormSchema } from "../stages/StagePhoneNumber";
import { stageSMSVerificationFormSchema } from "../stages/StageSMSVerification";

const onboardingFormSchema = z
  .object({
    isNotCertified: z.boolean().optional(),
  })
  .merge(stageNameFormSchema)
  .merge(stagePhoneNumberFormSchema())
  .merge(stageSMSVerificationFormSchema())
  .merge(stageCertificationsFormSchema())
  .merge(stageAddressFormSchema());

export type OnboardingFormData = z.infer<typeof onboardingFormSchema>;

const formStorageAtom = atomWithStorage<Partial<OnboardingFormData>>("onboarding-form", {});

const useFormStorage = () => {
  const [form, setForm] = useAtom(formStorageAtom);

  const resetForm = () => {
    setForm(RESET);
  };

  return { form: form, setForm: setForm, resetForm: resetForm };
};

export default useFormStorage;
