import styled from "styled-components";
import Stars from "../Stars";
import Avatar from "../../../components/Avatar";
import CaregiverReviewText from "./CaregiverReviewText";
import { Review } from "./CaregiverReviewsData";

interface Props {
  offset: number;
  review: Review;
  isShownMore: boolean;
  toggleShowMore: () => void;
}

const CaregiverReview = (props: Props) => {
  const { review } = props;

  return (
    <CaregiverReview.Styled {...props}>
      <Stars rating={review.score} size="small" />
      <CaregiverReviewText
        isShownMore={props.isShownMore}
        onClickShowMore={props.toggleShowMore}
        text={review.text}
      />
      <CaregiverReview.Profile>
        <Avatar size={40} imageUrl={review.avatar} />
        <CaregiverReview.ProfileName>{review.name.toUpperCase()}</CaregiverReview.ProfileName>
      </CaregiverReview.Profile>
    </CaregiverReview.Styled>
  );
};

CaregiverReview.Styled = styled.div<Props>`
  transform: ${(props) =>
    `translateX(calc(-${props.offset}*(90vw + 12px) + ${props.offset > 0 ? "3px" : 0}))`};

  display: flex;
  flex-direction: column;

  min-width: 90vw;
  margin-right: 12px;
  padding: 16px;

  border-radius: 15px;
  box-shadow: 0px 6px 48px 0px #04142f14;

  transition: all 0.5s ease-in-out;

  &:last-child {
    margin-right: unset;
  }
`;

CaregiverReview.Profile = styled.div`
  display: flex;
  align-items: center;

  margin-top: 10px;
`;

CaregiverReview.ProfileName = styled.div`
  margin-left: 12px;

  font-size: 10px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.palette.gray["500"]};
`;

export default CaregiverReview;
