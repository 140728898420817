import { useTranslation } from "react-i18next";
import Button from "./Button";
import { ReactComponent as ClockSvg } from "/public/clock.svg";
import styled from "styled-components";

interface Props {
  isLoading: boolean;
  isDisabled: boolean;
  trackId: string;
  buttonText?: string;
  onClick?: () => void;
}

function NextButton(props: Props) {
  const { t } = useTranslation();

  const text = props.buttonText ?? t("next");
  const isDisabled = props.isDisabled || props.isLoading;

  return (
    <NextButton.Styled
      id={`track-${props.trackId}`}
      size={"large"}
      type={props.onClick === undefined ? "submit" : "button"}
      disabled={isDisabled}
      onClick={props.onClick}
    >
      {props.isLoading ? (
        <>
          <ClockSvg />
          <span>{t("please-wait")}</span>
        </>
      ) : (
        text
      )}
    </NextButton.Styled>
  );
}

NextButton.Styled = styled(Button)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  svg {
    height: 24px;

    color: ${(props) => props.theme.colors.static.neutral};

    animation: ${(props) => props.theme.animations.rotate} 1s linear infinite;
  }
`;

export default NextButton;
