import styled from "styled-components";

interface Props {
  title: string;
  subtitle?: string;
}

const StyledQuestionHeading = styled.div`
  margin-bottom: 8px;
`;

const PrimaryHeading = styled.h1`
  padding-bottom: 12px;

  font-size: 24px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.static.inverted};
`;

const SecondaryHeading = styled.h2`
  padding-bottom: 18px;

  font-size: 18px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.palette.gray[400]};
`;

function QuestionHeading({ title, subtitle }: Props) {
  return (
    <StyledQuestionHeading>
      <PrimaryHeading>{title}</PrimaryHeading>
      {subtitle !== undefined && <SecondaryHeading>{subtitle}</SecondaryHeading>}
    </StyledQuestionHeading>
  );
}

export default QuestionHeading;
