import { zodResolver } from "@hookform/resolvers/zod";
import Form from "@shared/components/Form";
import { StageProps } from "@shared/utils/stageUtils";
import { useForm } from "react-hook-form";

import QuestionHeading from "@shared/components/QuestionHeading";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { OnboardingFormData } from "../hooks/useOnboardingFormStorage";
import Question from "@shared/components/Question";
import NextButton from "@shared/components/NextButton";
import styled from "styled-components";

type Props = StageProps<StageFormData, OnboardingFormData> & { isLoading: boolean };
type StageFormData = z.infer<ReturnType<typeof stagePhoneNumberFormSchema>>;

export const stagePhoneNumberFormSchema = (errorMessages?: { phoneNumber: string }) =>
  z.object({
    phoneNumber: z
      .string({ required_error: errorMessages?.phoneNumber })
      .length(12, errorMessages?.phoneNumber),
  });

const StagePhoneNumber = (props: Props) => {
  const { t } = useTranslation();
  const { control, handleSubmit, formState } = useForm<StageFormData>({
    resolver: zodResolver(stagePhoneNumberFormSchema({ phoneNumber: t("invalid-phone-number") })),
    defaultValues: props.form,
    mode: "onChange",
  });

  return (
    <Form role="form" onSubmit={handleSubmit(props.onValidSubmit, props.onInvalidSubmit)}>
      <QuestionHeading
        title={t("welcome-firstname-whats-your-phone-number", {
          firstName: props.form.firstName,
        })}
        subtitle={t("this-is-the-number-you-will-be-working-with")}
      />

      <Question>
        <Question.PhoneInput control={control} isDisabled={false} />
        <InfoText>
          {t("we-will-send-a-verification-code-to-this-number")} {t("no-spam-we-promise")}
        </InfoText>
      </Question>

      <NextButton
        buttonText={t("send-sms")}
        trackId="phonenumber-btn"
        isDisabled={!formState.isValid}
        isLoading={props.isLoading}
      />
    </Form>
  );
};

const InfoText = styled.p`
  margin: 12px 0;

  font-weight: 400;
  color: ${(props) => props.theme.colors.palette.gray[400]};
`;

export default StagePhoneNumber;
