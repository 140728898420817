import styled from "styled-components";

const PhoneScreenImage = ({
  src = "/landing/why-join/endless-visits/screen.png",
}: {
  src?: string;
}) => <PhoneScreenImage.Styled src={src} />;

PhoneScreenImage.Styled = styled.img`
  width: 40%;
  margin-top: 24px;
`;

export default PhoneScreenImage;
