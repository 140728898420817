import Button from "@shared/components/Button";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Heading1 from "../../components/Heading1";
import HomeSection from "../../components/HomeSection";
import SubHeading from "../../components/SubHeading";

interface Props {
  onClickStartOnboarding: () => void;
}

const WhoIsMedflytSection = (props: Props) => {
  const { t } = useTranslation();

  return (
    <HomeSection align="center" backgroundColor="#EFF3FB">
      <Heading>{t("home-who-is-medflyt-title")}</Heading>
      <SubHeading align="center">{t("home-who-is-medflyt-subtitle")}</SubHeading>
      <ImageContainer>
        <img width={180} src="./landing/who-is-medflyt.png" />
      </ImageContainer>
      <ButtonContainer>
        <Button
          size="large"
          variant="sexy"
          data-track="join-now-btn-who-is-medflyt"
          onClick={props.onClickStartOnboarding}
        >
          {t("join-us")}
        </Button>
      </ButtonContainer>
    </HomeSection>
  );
};

const Heading = styled(Heading1)`
  margin-bottom: 8px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 20px;
  margin-bottom: -40px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 50px;
`;

export default WhoIsMedflytSection;
