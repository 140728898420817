import styled, { keyframes } from "styled-components";
import { useState } from "react";
import { motion } from "framer-motion";
import { ReactComponent as CollapseSvg } from "/public/landing/question-collapse.svg";
import { ReactComponent as ExpandSvg } from "/public/landing/question-expand.svg";

import { CaregiverQuestion } from "./CaregiverQuestionsData";

interface CaregiverQuestionProps {
  question: CaregiverQuestion;
}

const CaregiverQuestionCard = (props: CaregiverQuestionProps) => {
  const {
    question: { question, answer },
  } = props;
  const [expanded, setExpanded] = useState(false);

  return (
    <Container>
      <TitleContainer>
        <Title>{question}</Title>
        <ExpandButtonContainer onClick={() => setExpanded(!expanded)}>
          <ExpandButton>{expanded ? <CollapseSvg /> : <ExpandSvg />}</ExpandButton>
        </ExpandButtonContainer>
      </TitleContainer>
      <QuestionText
        initial={
          !expanded
            ? { height: "auto", opacity: 1, marginTop: 4 }
            : { height: 0, opacity: 0, marginTop: 0 }
        }
        animate={
          expanded
            ? { height: "auto", opacity: 1, marginTop: 4 }
            : { height: 0, opacity: 0, marginTop: 0 }
        }
        transition={{ type: "tween", duration: 0.6 }}
        expanded={expanded}
      >
        {answer.split("\n").map((text, i) => (
          <p key={i}>{text}</p>
        ))}
      </QuestionText>
    </Container>
  );
};

const tada = keyframes`
  0% {
    opacity: 0;

    scale: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    scale: 1;
  }
`;

const Container = styled.div`
  width: 100%;
  margin-bottom: 12px;
  padding: 12px 16px;

  background: #ffffff;
  border-radius: 12px;

  animation: ${tada} 0.1s ease-in-out;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const ExpandButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: -12px -16px;
  padding: 12px 16px;
`;

const ExpandButton = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  margin-left: 8px;

  font-weight: 900;
  color: ${(props) => props.theme.colors.palette.blue["500"]};

  background-color: ${(props) => props.theme.colors.palette.blue["100"]};
  border-radius: 24px;
`;
const Title = styled.div`
  display: flex;
  align-items: center;

  min-height: 24px;

  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const QuestionText = styled(motion.div)<{ expanded: boolean }>`
  overflow: hidden;

  margin-right: 32px;

  font-size: 14px;
  font-weight: 50;
  line-height: 20px;
  color: ${(props) => props.theme.colors.palette.gray["500"]};

  p + p {
    margin-top: 4px;
  }
`;

export default CaregiverQuestionCard;
