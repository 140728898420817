import styled, { keyframes } from "styled-components";

const BenefitsInAppCard = () => (
  <>
    <SplashImage src="/landing/why-join/benefits-in-app/shapes.png" />
    <BackgroundImage src={"/landing/why-join/benefits-in-app/girl.png"} />
  </>
);

const scale = keyframes`
  0% {
    scale: 100%;
  }
  50% {
    scale: 90%;
  }
  100% {
    scale: 100%;
  }
`;

const SplashImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  animation: ${scale} 6s ease-in-out infinite;
`;

const BackgroundImage = styled.img`
  width: 80%;
  margin-top: 24px;
`;

export default BenefitsInAppCard;
