import { TFunction } from "react-i18next";

export interface Review {
  score: number;
  text: string;
  avatar: string;
  name: string;
}

export const getReviews = (t: TFunction): Review[] => {
  return [
    {
      score: 4,
      text: t("home-caregiver-reviews-reviews-1-text"),
      avatar: "/landing/reviews/photo-woman-3.jpeg",
      name: t("home-caregiver-reviews-reviews-1-name"),
    },
    {
      score: 5,
      text: t("home-caregiver-reviews-reviews-2-text"),
      avatar: "/landing/reviews/photo-woman-1.jpeg",
      name: t("home-caregiver-reviews-reviews-2-name"),
    },
    {
      score: 5,
      text: t("home-caregiver-reviews-reviews-3-text"),
      avatar: "/landing/reviews/photo-man-3.jpeg",
      name: t("home-caregiver-reviews-reviews-3-name"),
    },
    {
      score: 5,
      text: t("home-caregiver-reviews-reviews-4-text"),
      avatar: "/landing/reviews/photo-man-4.jpeg",
      name: t("home-caregiver-reviews-reviews-4-name"),
    },
    {
      score: 5,
      text: t("home-caregiver-reviews-reviews-5-text"),
      avatar: "/landing/reviews/photo-woman-4.jpeg",
      name: t("home-caregiver-reviews-reviews-5-name"),
    },
  ];
};
