import themeBreakpoints from "./theme.breakpoints";
import themeColors from "./theme.colors";
import themeZIndex from "./theme.zIndex";
import themeAnimations from "./theme.animations";
export { ThemeProvider } from "styled-components";

const theme = {
  breakpoints: themeBreakpoints,
  colors: themeColors,
  zIndex: themeZIndex,
  animations: themeAnimations,
};

export type Theme = typeof theme;

export default theme;
