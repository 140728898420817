import styled from "styled-components";

interface Props {
  text: string;
  isShownMore: boolean;
  onClickShowMore: () => void;
}

const CaregiverReviewText = ({ text, isShownMore, onClickShowMore }: Props) => {
  const shortText = sliceStringByWord(text, 200);
  const isTextSpliced = shortText.length < text.length;

  return isTextSpliced && !isShownMore ? (
    <CaregiverReviewText.Styled>
      <span>{shortText}...</span>
      <CaregiverReviewText.More onClick={onClickShowMore}>more</CaregiverReviewText.More>
    </CaregiverReviewText.Styled>
  ) : (
    <CaregiverReviewText.Styled>{text}</CaregiverReviewText.Styled>
  );
};

CaregiverReviewText.Styled = styled.div`
  flex-grow: 1;

  margin-top: 10px;

  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.palette.gray["500"]};
`;

CaregiverReviewText.More = styled.span`
  margin-left: 4px;

  font-weight: 600;
  color: ${(props) => props.theme.colors.palette.blue["500"]};
`;

const sliceStringByWord = (str: string, maxLength: number) =>
  `${str}`
    .split(" ")
    .reduce((short, word) =>
      short.length + " ".length + word.length < maxLength ? `${short} ${word}` : short
    );

export default CaregiverReviewText;
