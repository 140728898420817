import { ApiConfig } from "@shared/hooks/HttpApi/useHttpApi";
import { createContext } from "react";

export function getInitialApiConfig(): ApiConfig {
  if (import.meta.env.VITE_API_URL !== undefined) {
    return { baseUrl: import.meta.env.VITE_API_URL };
  }

  return apiConfig.prod;
}

export const apiConfig: Record<"local" | "prod", ApiConfig> = {
  local: {
    baseUrl: "http://localhost:3000/",
  },
  prod: {
    baseUrl: "https://api.medflyt.com/",
  },
};

export const ApiConfigContext = createContext(apiConfig.local);
