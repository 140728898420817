import axios, { AxiosResponse } from "axios";
import { either } from "fp-ts";
import { useContext } from "react";
import { ApiConfigContext } from "../../../apiConfigContext";
import {
  I9ReviewerDetailsError,
  I9ReviewerResponseData,
  I9ReviewerSignatureRequest,
  I9ReviewerSignError,
  OnBoardingCaregiverDetailsRequest,
  OnboardingCaregiverDetailsResponse,
  OnboardingCompleteRegistrationError,
  OnboardingProccessSMSCodeRequest,
  OnboardingProccessSMSCodeResponse,
  OnboardingProccessSMSRequest,
  OnboardingProcessTokenError,
  OnboardingProcessTokenResponse,
  UpdateOnboardingDataResponse,
} from "./HttpApiMessages";

export interface ApiConfig {
  baseUrl: string;
}

export class ServerError<Err> extends Error {
  constructor(public statusCode: number, public url: string, public data?: Err) {
    super();
  }
}

export function useHttpApi() {
  const context = useContext(ApiConfigContext);

  const http = axios.create({
    validateStatus: (code) => code < 500,
    baseURL: context.baseUrl,
    headers: {
      "Content-Type": "application/json",
      Accepts: "application/json",
    },
  });

  return {
    startOnboardingProcess: (params: { campaign: string | null }) => {
      return wrapRequest<OnboardingProcessTokenResponse, OnboardingProcessTokenError>(
        http.post("/onboarding/caregivers", undefined, {
          params: {
            campaign: params.campaign,
          },
        })
      );
    },

    updateOnboardingData: (params: { token: string; data: OnBoardingCaregiverDetailsRequest }) => {
      return wrapRequest<UpdateOnboardingDataResponse, OnboardingCompleteRegistrationError>(
        http.patch("onboarding/caregivers/:token".replace(":token", params.token), params.data)
      );
    },

    getOnboardingData: (params: { token: string }) => {
      return wrapRequest<OnboardingCaregiverDetailsResponse, void>(
        http.get("onboarding/caregivers/:token".replace(":token", params.token))
      );
    },

    sendSmsVerificationCode: (data: OnboardingProccessSMSRequest) => {
      return wrapRequest<void, Error>(http.post("/onboarding/send_sms", data));
    },

    VerifySmsCode: (data: OnboardingProccessSMSCodeRequest) => {
      return wrapRequest<OnboardingProccessSMSCodeResponse, Error>(
        http.post("/onboarding/verify_sms_code", data)
      );
    },

    getI9ReviewerData: ({ token }: { token: string }) => {
      return wrapRequest<I9ReviewerResponseData, I9ReviewerDetailsError>(
        http.get(`/i9_reviewer/${token}`)
      );
    },

    sendI9ReviewerData: ({
      token,
      signature,
      approved,
    }: {
      token: string;
      signature: string;
      approved: boolean;
    }) => {
      return wrapRequest<I9ReviewerSignatureRequest, I9ReviewerSignError>(
        http.post(`/i9_reviewer/${token}`, { base64Signature: signature, approved: approved })
      );
    },
  };
}

async function wrapRequest<DataResponse, ErrorResponse>(
  promise: Promise<AxiosResponse<DataResponse | ErrorResponse>>
): Promise<either.Either<ErrorResponse, DataResponse>> {
  const response = await promise;

  if (response.status >= 400) {
    return either.left(response.data as ErrorResponse);
  }

  return either.right(response.data as DataResponse);
}
