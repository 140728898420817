import styled from "styled-components";
import Logo from "@shared/components/Logo";
import Button from "@shared/components/Button";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  onClickStartOnboarding: () => Promise<void>;
}

const Navigation = ({ onClickStartOnboarding, show }: Props) => {
  const { t } = useTranslation();

  return (
    <Navigation.Styled show={show}>
      <Logo />
      <ButtonContainer>
        <Button
          size="medium"
          variant="sexy"
          data-track="join-now-btn"
          onClick={onClickStartOnboarding}
        >
          {t("home-apply-now")}
        </Button>
      </ButtonContainer>
    </Navigation.Styled>
  );
};

Navigation.Styled = styled.nav<{ show: boolean }>`
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  left: 0;
  transform: translateY(${({ show }) => (show ? 0 : -100)}%);

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 64px;
  padding: 8px 16px;

  background-color: white;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.palette.blue["50"]}`};

  transition: all 0.1s ease-in-out;
`;

const ButtonContainer = styled.div`
  width: 40vw;
`;

export default Navigation;
