import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SadSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

interface Props {
  error: unknown;
}

const ErrorRoute = (props: Props) => {
  const { t } = useTranslation();

  const getErrorMessage = () => {
    if (props.error instanceof Error) {
      return props.error.message;
    }

    if (typeof props.error === "string") {
      return props.error;
    }

    if (props.error === null || props.error === undefined) {
      return "Something went wrong";
    }

    if (isServerCustomError(props.error)) {
      return props.error.error.error;
    }

    return JSON.stringify(props.error);
  };

  return (
    <ErrorRoute.Styled>
      <SadSvg />

      <h3>{getErrorMessage()}</h3>

      <ErrorButton as={Link} to="/">
        {t("back-to-main-page")}
      </ErrorButton>
    </ErrorRoute.Styled>
  );
};

const ErrorButton = styled.button`
  padding: 12px 16px;

  background-color: ${(props) => props.theme.colors.negative(50)};
  border-radius: 8px;

  &:hover {
    cursor: pointer;

    color: ${(props) => props.theme.colors.static.neutral};

    background-color: ${(props) => props.theme.colors.negative()};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.negative(600)};
  }
`;

ErrorRoute.Styled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;

  height: 100%;

  font-weight: 600;
  color: ${(props) => props.theme.colors.palette.red[500]};

  svg {
    height: 48px;
  }
`;

function isServerCustomError(error: unknown): error is { error: { error: string } } {
  return error !== null && typeof error === "object" && "error" in error;
}

export default ErrorRoute;
