import styled from "styled-components";
import { useState } from "react";

import { ReactComponent as PlayBtnSvg } from "/public/video-play-btn.svg";

interface Props {
  videoId: string;
}

const VimeoVideo = (props: Props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const togglePlay = () => setIsPlaying(!isPlaying);
  const src = `https://player.vimeo.com/video/${
    props.videoId
  }?title=0&portrait=0&badge=0&controls=${isPlaying ? 1 : 0}&autoplay=${isPlaying ? 1 : 0}`;

  return (
    <VimeoVideo.Styled>
      <iframe src={src} frameBorder="0" allowFullScreen allow="autoplay; encrypted-media" />
      {!isPlaying ? (
        <PlayBtnOverlay onClick={togglePlay}>
          <PlayBtnSvg width="68px" height="68px" />
        </PlayBtnOverlay>
      ) : null}
    </VimeoVideo.Styled>
  );
};

VimeoVideo.Styled = styled.div`
  position: relative;

  overflow: hidden;

  width: 100%;
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  border-radius: 12px;

  & iframe,
  & object,
  & embed {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
`;

const PlayBtnOverlay = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

export default VimeoVideo;
