import Button from "@shared/components/Button";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Heading1 from "../../components/Heading1";
import HomeSection from "../../components/HomeSection";
import SubHeading from "../../components/SubHeading";
import BenefitCard from "./Benefits/BenefitCard";
import { getBenefits } from "./Benefits/BenefitsData";

interface Props {
  onClickStartOnboarding: () => void;
}

const JoinMedflytSection = (props: Props) => {
  const { t } = useTranslation();
  const benefits = getBenefits(t);

  return (
    <HomeSection>
      <Heading>{t("home-join-medflyt-title")}</Heading>
      <SubHeading>{t("home-join-medflyt-subtitle")}</SubHeading>
      {benefits.map((benefit, i) => (
        <BenefitCard key={i} title={benefit.title} subtitle={benefit.subtitle}>
          {benefit.card}
        </BenefitCard>
      ))}
      <ButtonContainer>
        <Button
          size="large"
          variant="sexy"
          data-track="join-now-btn-join-medflyt"
          onClick={props.onClickStartOnboarding}
        >
          {t("home-apply-now")}
        </Button>
      </ButtonContainer>
    </HomeSection>
  );
};

const Heading = styled(Heading1)`
  margin-bottom: 8px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 12px;
`;

export default JoinMedflytSection;
