import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import styled from "styled-components";

interface Props {
  prediction: google.maps.places.AutocompletePrediction;
  query: string;
  onClick: (prediction: google.maps.places.AutocompletePrediction) => void;
}

function Prediction(props: Props) {
  const matches = match(props.prediction.description, props.query);
  const parts = parse(props.prediction.description, matches);

  return (
    <Prediction.Styled onClick={() => props.onClick(props.prediction)}>
      {parts.map((part, index) => (
        <Prediction.Highlight key={index} highlight={part.highlight}>
          {part.text}
        </Prediction.Highlight>
      ))}
    </Prediction.Styled>
  );
}

Prediction.Styled = styled.p`
  padding: 12px;
`;

Prediction.Highlight = styled.span<{ highlight: boolean }>`
  font-weight: ${(props) => (props.highlight ? "bold" : "normal")};
`;

export default Prediction;
