import styled from "styled-components";
import QuestionWrapperStyled from "./QuestionWrapperStyled";

const StyledQuestionLabel = styled.span`
  display: flex;
`;

const QuestionLabel = (props: { children: React.ReactNode }) => {
  return (
    <QuestionWrapperStyled>
      <StyledQuestionLabel>{props.children}</StyledQuestionLabel>
    </QuestionWrapperStyled>
  );
};

export default QuestionLabel;
