import QuestionCheckbox from "./QuestionCheckbox";
import QuestionInput from "./QuestionInput";
import QuestionLabel from "./QuestionLabel";
import QuestionPhoneNumberInput from "./QuestionPhoneNumberInput";
import QuestionRadio from "./QuestionRadio";
import QuestionSelect from "./QuestionSelect";
import Question from "./Question";
import QuestionSmsCode from "./QuestionSmsCode";
import QuestionYesNo from "./QuestionYesNo";

export default Object.assign(Question, {
  Label: QuestionLabel,
  Input: QuestionInput,
  PhoneInput: QuestionPhoneNumberInput,
  Checkbox: QuestionCheckbox,
  Radio: QuestionRadio,
  Select: QuestionSelect,
  SmsCodeInput: QuestionSmsCode,
  YesNo: QuestionYesNo,
});
