import { TFunction } from "react-i18next";

export interface Testimonial {
  name: string;
  text: string;
  image: string;
}

export const getTestimonials = (t: TFunction): Testimonial[] => {
  return [
    {
      name: t("home-caregivers-testimonials-testimonials-1-name"),
      text: t("home-caregivers-testimonials-testimonials-1-text"),
      image: "/landing/testimonials/testimonial-1.jpeg",
    },
    {
      name: t("home-caregivers-testimonials-testimonials-2-name"),
      text: t("home-caregivers-testimonials-testimonials-2-text"),
      image: "/landing/testimonials/testimonial-2.jpeg",
    },
  ];
};
