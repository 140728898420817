import styled from "styled-components";

const QuestionWrapperStyled = styled.div`
  display: flex;
  align-items: center;

  border: 2px solid ${({ theme }) => theme.colors.palette.gray[100]};
  border-radius: 16px;

  &[data-disabled="true"] {
    background-color: ${(props) => props.theme.colors.palette.gray[100]};
    border-color: ${(props) => props.theme.colors.palette.gray[200]};
  }
`;

export default QuestionWrapperStyled;
