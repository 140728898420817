import styled, { keyframes } from "styled-components";

interface VisitCardProps {
  translateX: number;
}

const pulse = (props: VisitCardProps) => keyframes`
  0% {
    transform: translateX(${props.translateX}%);;

    scale: 100%;
  }
  50% {
    transform: translateX(${props.translateX * 0.9}%);;

    scale: 96%;
  }
  100% {
    transform: translateX(${props.translateX}%);;

    scale: 100%;
  }
`;

export const VisitEventListItem = styled.img<VisitCardProps>`
  transform: translateX(${(props) => props.translateX}%);

  width: 46%;

  filter: drop-shadow(0px 6px 48px rgba(4, 20, 47, 0.08));

  animation: ${(props) => pulse(props)} 6s ease-in-out infinite;
`;

export const VisitsEventsList = styled.div<{ bottom: number }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: ${(props) => props.bottom}px;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  ${VisitEventListItem} + ${VisitEventListItem} {
    margin-top: 6px;
  }
`;
