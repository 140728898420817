import { TFunction } from "react-i18next";

export interface CaregiverQuestion {
  question: string;
  answer: string;
}

const questionCount = 11;

export const getQuestions = (t: TFunction): CaregiverQuestion[] => {
  const questions: CaregiverQuestion[] = [];

  const getQuestion = (n: number) => ({
    question: t(`home-caregivers-ask-questions-${n}-question`),
    answer: t(`home-caregivers-ask-questions-${n}-answer`),
  });

  for (let i = 0; i < questionCount; i++) {
    questions.push(getQuestion(i + 1));
  }
  return questions;
};
