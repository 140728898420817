import { useEffect, useState } from "react";

import Navigation from "./components/Navigation";
import MainSection from "./sections/Main/MainSection";
import CaregiversReviewsSection from "./sections/CaregiversReviews/CaregiversReviewsSection";
import WhoIsMedflytSection from "./sections/WhoIsMedflyt/WhoIsMedflytSection";
import JoinMedflytSection from "./sections/JoinMedflyt/JoinMedflytSection";
import CaregiversSpeakSection from "./sections/CaregiversSpeak/CaregiversSpeakSection";
import HowToStartSection from "./sections/HowToStart/HowToStartSection";
import CaregiversAskSection from "./sections/CaregiversAsk/CaregiversAskSection";
import CaregiverTestimonialsSection from "./sections/CaregiverTestimonials/CaregiverTestimonialsSection";

const HomeView = ({ onClickStartOnboarding }: { onClickStartOnboarding: () => Promise<void> }) => {
  const [show, setShow] = useState(false);
  const [windowScrollY, setWindowScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      const clientHeight = document.documentElement.clientHeight;
      if (window.scrollY < clientHeight - 64) {
        setShow(false);
      } else {
        setShow(true);
      }

      setWindowScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      controlNavbar();
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  });

  return (
    <>
      <Navigation show={show} onClickStartOnboarding={onClickStartOnboarding} />
      <MainSection windowScrollY={windowScrollY} onClickStartOnboarding={onClickStartOnboarding} />
      <CaregiversReviewsSection />
      <WhoIsMedflytSection onClickStartOnboarding={onClickStartOnboarding} />
      <JoinMedflytSection onClickStartOnboarding={onClickStartOnboarding} />
      <CaregiversSpeakSection />
      <HowToStartSection onClickStartOnboarding={onClickStartOnboarding} />
      <CaregiversAskSection />
      <CaregiverTestimonialsSection onClickStartOnboarding={onClickStartOnboarding} />
    </>
  );
};

export default HomeView;
