import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import "the-new-css-reset/css/reset.css";
import { ApiConfigContext, getInitialApiConfig } from "./apiConfigContext";
import App from "./App";
import "./i18n";
import "./index.css";

const rootElem = document.getElementById("root");

if (rootElem === null) {
  throw new Error("Root element not found");
}

const queryClient = new QueryClient();

ReactDOM.createRoot(rootElem).render(
  <React.StrictMode>
    <ApiConfigContext.Provider value={getInitialApiConfig()}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ApiConfigContext.Provider>
  </React.StrictMode>
);
