import styled from "styled-components";
import CaregiverTestimonial from "./CaregiverTestimonial";
import { useTranslation } from "react-i18next";
import { getTestimonials } from "./CaregiverTestimonialsData";

const CaregiverTestimonialsCarousel = () => {
  const { t } = useTranslation();
  const testimonials = getTestimonials(t);

  return (
    <CaregiverTestimonialsCarousel.Styled>
      <CaregiverTestimonialsContainer>
        {testimonials.map((testimonial, i) => (
          <CaregiverTestimonial key={i} testimonial={testimonial} />
        ))}
      </CaregiverTestimonialsContainer>
    </CaregiverTestimonialsCarousel.Styled>
  );
};

CaregiverTestimonialsCarousel.Styled = styled.div`
  position: relative;

  margin: 0 -16px;
`;

const CaregiverTestimonialsContainer = styled.div`
  position: relative;

  overflow-x: scroll;
  display: flex;

  margin-top: 16px;
  padding: 0 16px;
`;

export default CaregiverTestimonialsCarousel;
