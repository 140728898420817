import Button from "@shared/components/Button";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import styled, { css, keyframes } from "styled-components";
import CaregiversMap from "./Map/CaregiversMap";
import Content from "./Content/Content";
import { ReactComponent as ArrowDownSvg } from "/public/landing/arrow-down.svg";

interface Props {
  onClickStartOnboarding: () => Promise<void>;
  windowScrollY: number;
}

const MainSection = ({ windowScrollY, onClickStartOnboarding }: Props) => {
  const { t } = useTranslation();

  const isScrollingStarted = windowScrollY > 20;
  const clientHeight = document.documentElement.clientHeight;
  const navigationHeight = 64;
  const scrollToSecondSection = () =>
    window.scrollTo({ top: clientHeight - navigationHeight, behavior: "smooth" });

  return (
    <MainSection.Styled>
      <ContentContainer>
        <Content />
      </ContentContainer>

      <ButtonContainer>
        <Button
          size="large"
          variant="sexy"
          data-track="join-now-btn"
          onClick={onClickStartOnboarding}
        >
          {t("home-apply-now")}
        </Button>
      </ButtonContainer>

      <ArrowContainer>
        <ArrowDown onClick={scrollToSecondSection} isScrollingStarted={isScrollingStarted}>
          <ArrowDownSvg />
        </ArrowDown>
      </ArrowContainer>

      <NextPagePiece isScrollingStarted={isScrollingStarted} />

      <MapContainer>
        <CaregiversMap />
      </MapContainer>
    </MainSection.Styled>
  );
};

MainSection.Styled = styled.section`
  position: relative;

  height: 100%;
  min-height: 100%;
`;
const contentContainerAnimation = keyframes`
  0% {
    transform: translateY(-100%);

    opacity: 0;
  }
  100% {
    transform: translateY(0);

    opacity: 1;
  }
`;

const ContentContainer = styled(motion.div)`
  position: fixed;
  top: 40px;

  animation: ${contentContainerAnimation} 1.5s ease-in-out;
`;

const buttonShowAnimation = keyframes`
  0% {
    transform: translateY(100%);

    opacity: 0;
  }
  100% {
    transform: translateY(0);

    opacity: 1;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 40px;

  width: 100vw;
  padding: 16px;

  animation: ${buttonShowAnimation} 0.5s ease-in-out;
`;

const ArrowContainer = styled.div`
  position: fixed;
  bottom: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 40px;
  margin: 8px 0;
`;

const bounce = keyframes`
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
  60% {
    transform: translateY(-4px);
  }
`;

const fade = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
const show = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ArrowDown = styled.div<{ isScrollingStarted: boolean }>`
  width: 40px;

  opacity: 1;

  ${({ isScrollingStarted }) =>
    isScrollingStarted
      ? css`
          opacity: 0;

          animation: ${fade} 2s ease-in-out;
        `
      : css`
          opacity: 1;

          animation: ${show} 2s ease-in-out, ${bounce} 2s ease-in-out infinite;
        `}
`;

const jumping = keyframes`
  0% {
    height: 0;
  }
  50% {
    height: 10px;
  }
`;

const NextPagePiece = styled.div<{ isScrollingStarted: boolean }>`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 0;

  background-color: white;

  ${({ isScrollingStarted }) =>
    !isScrollingStarted &&
    css`
      animation: ${jumping} 3s ease-in-out infinite;
    `}
`;

const MapContainer = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
`;

export default MainSection;
