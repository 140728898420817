import styled from "styled-components";

interface Props {
  backgroundColor?: string;
  align?: "stretch" | "center";
}

const HomeSection = styled.section<Props>`
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align ?? "stretch"};

  padding: 40px 16px;

  background-color: ${(props) => props.backgroundColor ?? "white"};
`;

export default HomeSection;
