import { useHttpApi } from "@shared/hooks/HttpApi/useHttpApi";
import { useToken } from "@shared/hooks/HttpApi/useToken";
import { throwOnNull } from "@shared/utils/commonUtils";
import { useQuery } from "react-query";
import NotCertifiedView from "./NotCertifiedView";

const NotCertifiedRoute = () => {
  const { token } = useToken();
  const api = useHttpApi();

  useQuery(
    ["not-certified", token],
    () =>
      api.updateOnboardingData({
        token: throwOnNull(token),
        data: { isNotCertified: true },
      }),
    {
      enabled: token !== null,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return <NotCertifiedView />;
};

export default NotCertifiedRoute;
