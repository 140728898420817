import matchUtils from "@shared/utils/matchUtils";
import { either } from "fp-ts";
import { pipe } from "fp-ts/function";
import { RESET } from "jotai/utils";
import { useMutation, useQuery } from "react-query";
import { match } from "ts-pattern";
import useFormStorage from "../../routes/OnboardingRoute/hooks/useOnboardingFormStorage";
import { useHttpApi } from "./HttpApi/useHttpApi";
import { useToken } from "./HttpApi/useToken";

export function useInitOnboardingProcess() {
  const { token, setToken } = useToken();
  const form = useFormStorage();
  const api = useHttpApi();

  useQuery(["/onboarding/caregivers"], () => api.startOnboardingProcess({ campaign: null }), {
    enabled: token === null,
    onSuccess: (data) => {
      match(data)
        .with(matchUtils.either.isLeft, (error) => console.log("error", error))
        .with(matchUtils.either.isRight, ({ data }) => {
          setToken(data.token);
          form.resetForm();
        })
        .exhaustive();
    },
  });

  const { mutateAsync: resetOnboarding } = useMutation({
    mutationFn: api.startOnboardingProcess,
    onSuccess: (response) =>
      pipe(
        response,
        either.fold(
          (error) => console.log("error", error),
          ({ token }) => {
            form.setForm(RESET);
            setToken(token);
          }
        )
      ),
  });

  return { resetOnboarding };
}
