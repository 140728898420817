import styled from "styled-components";
import CaregiverReview from "./CaregiverReview";
import CarouselButton from "./CarouselButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getReviews } from "./CaregiverReviewsData";

const CaregiverReviewsCarousel = () => {
  const { t } = useTranslation();
  const reviews = getReviews(t);

  const [isShownMore, setShownMore] = useState(false);
  const toggleShowMore = () => setShownMore(!isShownMore);

  const [currentIndex, setCurrentIndex] = useState(0);
  const isNextEnabled = currentIndex < reviews.length - 1;
  const setNextIndex = () => {
    if (isNextEnabled) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const isPrevEnabled = currentIndex > 0;
  const setPrevIndex = () => {
    if (isPrevEnabled) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <CaregiverReviewsCarousel.Styled>
      <ReviewsContainer isShownMore={isShownMore}>
        {reviews.map((review, i) => (
          <CaregiverReview
            key={i}
            offset={currentIndex}
            review={review}
            isShownMore={isShownMore}
            toggleShowMore={toggleShowMore}
          />
        ))}
      </ReviewsContainer>
      <PrevScrollArea onClick={setPrevIndex} />
      <NextScrollArea onClick={setNextIndex} />
      <ButtonsContainer>
        <CarouselButton enabled={isPrevEnabled} onClick={setPrevIndex} icon="./arrow-left.svg" />
        <CarouselButton enabled={isNextEnabled} onClick={setNextIndex} icon="./arrow-right.svg" />
      </ButtonsContainer>
    </CaregiverReviewsCarousel.Styled>
  );
};

CaregiverReviewsCarousel.Styled = styled.div`
  position: relative;

  margin: 0 -16px;
`;

const NextScrollArea = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  width: 20px;
  height: 100%;

  background-color: rgba(204, 204, 204, 0.01);
`;

const PrevScrollArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 100%;

  background-color: rgba(204, 204, 204, 0.01);
`;

const ButtonsContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
`;

const ReviewsContainer = styled.div<{ isShownMore: boolean }>`
  position: relative;

  overflow-x: hidden;
  display: flex;

  padding: 24px 16px ${24 + 40}px 16px;

  transition: grow 2s;
`;

export default CaregiverReviewsCarousel;
