import { match } from "ts-pattern";

const themeColors = {
  primary(shade?: PaletteShade) {
    return this.palette.blue[shade ?? DEFAULT_SHADE];
  },
  positive(shade?: PaletteShade) {
    return this.palette.green[shade ?? DEFAULT_SHADE];
  },
  negative(shade?: PaletteShade) {
    return this.palette.red[shade ?? DEFAULT_SHADE];
  },
  warning(shade?: PaletteShade) {
    return this.palette.gold[shade ?? DEFAULT_SHADE];
  },
  static: {
    neutral: "hsla(0, 0%, 100%, 1)",
    inverted: "hsla(0, 0%, 0%, 1)",
  },
  extra: {
    background1: "hsla(180, 11%, 98%, 1)",
    background2: "hsla(209, 100%, 8%, 1)",
    background3: "hsla(209, 100%, 5%, 1)",
  },
  palette: {
    gray: {
      50: "hsla(210, 20%, 98%, 1)",
      100: "hsla(220, 14%, 96%, 1)",
      200: "hsla(220, 13%, 91%, 1)",
      300: "hsla(216, 12%, 84%, 1)",
      400: "hsla(218, 11%, 65%, 1)",
      500: "hsla(220, 9%, 46%, 1)",
      600: "hsla(215, 14%, 34%, 1)",
      700: "hsla(217, 19%, 27%, 1)",
      800: "hsla(215, 28%, 17%, 1)",
      900: "hsla(221, 39%, 11%, 1)",
    },
    blue: {
      50: "hsla(210, 92%, 97%, 1)",
      100: "hsla(210, 92%, 87%, 1)",
      200: "hsla(210, 92%, 77%, 1)",
      300: "hsla(210, 92%, 67%, 1)",
      400: "hsla(210, 92%, 57%, 1)",
      500: "hsla(210, 92%, 47%, 1)",
      600: "hsla(210, 92%, 37%, 1)",
      700: "hsla(210, 92%, 27%, 1)",
      800: "hsla(210, 92%, 17%, 1)",
      900: "hsla(210, 92%, 7%, 1)",
    },
    red: {
      50: "hsla(0, 86%, 97%, 1)",
      100: "hsla(0, 93%, 94%, 1)",
      200: "hsla(0, 96%, 89%, 1)",
      300: "hsla(0, 94%, 82%, 1)",
      400: "hsla(0, 91%, 71%, 1)",
      500: "hsla(0, 84%, 60%, 1)",
      600: "hsla(0, 72%, 51%, 1)",
      700: "hsla(0, 74%, 42%, 1)",
      800: "hsla(0, 70%, 35%, 1)",
      900: "hsla(0, 63%, 31%, 1)",
    },
    gold: {
      50: "hsla(48, 100%, 96%, 1)",
      100: "hsla(48, 96%, 89%, 1)",
      200: "hsla(48, 97%, 77%, 1)",
      300: "hsla(46, 97%, 65%, 1)",
      400: "hsla(43, 96%, 56%, 1)",
      500: "hsla(38, 92%, 50%, 1)",
      600: "hsla(32, 95%, 44%, 1)",
      700: "hsla(26, 90%, 37%, 1)",
      800: "hsla(23, 82%, 31%, 1)",
      900: "hsla(22, 78%, 26%, 1)",
    },
    green: {
      50: "hsla(152, 81%, 96%, 1)",
      100: "hsla(149, 80%, 90%, 1)",
      200: "hsla(152, 76%, 80%, 1)",
      300: "hsla(156, 72%, 67%, 1)",
      400: "hsla(158, 64%, 52%, 1)",
      500: "hsla(160, 84%, 39%, 1)",
      600: "hsla(161, 94%, 30%, 1)",
      700: "hsla(163, 94%, 24%, 1)",
      800: "hsla(163, 88%, 20%, 1)",
      900: "hsla(164, 86%, 16%, 1)",
    },
    magenta: {
      50: "hsla(327, 73%, 97%, 1)",
      100: "hsla(326, 78%, 95%, 1)",
      200: "hsla(326, 85%, 90%, 1)",
      300: "hsla(327, 87%, 82%, 1)",
      400: "hsla(329, 86%, 70%, 1)",
      500: "hsla(330, 81%, 60%, 1)",
      600: "hsla(333, 71%, 51%, 1)",
      700: "hsla(335, 78%, 42%, 1)",
      800: "hsla(336, 74%, 35%, 1)",
      900: "hsla(336, 69%, 30%, 1)",
    },
    indigo: {
      50: "hsla(226, 100%, 94%, 1)",
      100: "hsla(226, 100%, 94%, 1)",
      200: "hsla(228, 96%, 89%, 1)",
      300: "hsla(230, 94%, 82%, 1)",
      400: "hsla(234, 89%, 74%, 1)",
      500: "hsla(239, 84%, 67%, 1)",
      600: "hsla(243, 75%, 59%, 1)",
      700: "hsla(245, 58%, 51%, 1)",
      800: "hsla(244, 55%, 41%, 1)",
      900: "hsla(242, 47%, 34%, 1)",
    },
    purple: {
      50: "hsla(250, 100%, 98%, 1)",
      100: "hsla(251, 91%, 95%, 1)",
      200: "hsla(251, 95%, 92%, 1)",
      300: "hsla(253, 95%, 85%, 1)",
      400: "hsla(255, 92%, 76%, 1)",
      500: "hsla(258, 90%, 66%, 1)",
      600: "hsla(262, 83%, 58%, 1)",
      700: "hsla(263, 70%, 50%, 1)",
      800: "hsla(263, 69%, 42%, 1)",
      900: "hsla(263, 67%, 35%, 1)",
    },
  },
};

type ThemeColors = typeof themeColors;
type PaletteShade = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export type ThemeVariantColorName = "primary" | "positive" | "negative" | "warning";
export type ThemeVariantWithShadeColor = { variant: ThemeVariantColorName; shade: PaletteShade };
export type ThemeVariantColor = ThemeVariantColorName | ThemeVariantWithShadeColor;

export type ThemePaletteColorName = keyof ThemeColors["palette"];
export type ThemePaletteWithShadeColor = { palette: ThemePaletteColorName; shade: PaletteShade };
export type ThemePaletteColor = ThemePaletteColorName | ThemePaletteWithShadeColor;

export type ThemeStaticColorName = keyof ThemeColors["static"];

export type ThemeColor = ThemeStaticColorName | ThemePaletteColor | ThemeVariantColor;
export type ThemeColorName = ThemeStaticColorName | ThemePaletteColorName | ThemeVariantColorName;

const DEFAULT_SHADE: PaletteShade = 500;

function isPaletteColor(
  palette: ThemeColors["palette"],
  color: ThemeColor
): color is ThemePaletteColorName {
  return typeof color === "string" && color in palette;
}

function isPaletteWithValueColor(
  palettes: ThemeColors["palette"],
  color: ThemeColor
): color is ThemePaletteWithShadeColor {
  return typeof color === "object" && "palette" in color && color.palette in palettes;
}

function isStaticColor(
  statics: ThemeColors["static"],
  color: ThemeColor
): color is ThemeStaticColorName {
  return typeof color === "string" && color in statics;
}

function isVariantColor(
  themeColors: ThemeColors,
  color: ThemeColor
): color is ThemeVariantColorName {
  return typeof color === "string" && color in themeColors;
}

function isVariantWithShadeColor(
  themeColors: ThemeColors,
  color: ThemeColor
): color is ThemeVariantWithShadeColor {
  return typeof color === "object" && "variant" in color && color.variant in themeColors;
}

export function getOptionalThemeColor(
  colors: ThemeColors,
  color?: ThemeColor | null
): string | undefined {
  if (color === undefined || color === null) {
    return undefined;
  }

  return getThemeColor(colors, color);
}

export function toColorWithShade(
  colors: ThemeColors,
  colorName: ThemeColorName,
  shade: PaletteShade
): ThemeStaticColorName | ThemeVariantWithShadeColor | ThemePaletteWithShadeColor {
  if (isPaletteColor(colors.palette, colorName)) {
    return { palette: colorName, shade: shade };
  }

  if (isStaticColor(colors.static, colorName)) {
    return colorName;
  }

  if (isVariantColor(colors, colorName)) {
    return { variant: colorName, shade: shade };
  }

  return match(colorName).exhaustive();
}

export function getThemeColor(colors: ThemeColors, color: ThemeColor): string {
  if (isVariantWithShadeColor(themeColors, color)) {
    return themeColors[color.variant](color.shade);
  }

  if (isVariantColor(themeColors, color)) {
    return themeColors[color](DEFAULT_SHADE);
  }

  if (isPaletteWithValueColor(colors.palette, color)) {
    return colors.palette[color.palette][color.shade];
  }

  if (isStaticColor(colors.static, color)) {
    return colors.static[color];
  }

  if (isPaletteColor(colors.palette, color)) {
    return colors.palette[color][DEFAULT_SHADE];
  }

  return match(color).exhaustive();
}

export function withOpacity(hslaColor: string, opacity: number) {
  return hslaColor.replace(/1\)$/, `${opacity})`);
}

export const __internalThemeColors = {
  themeColors,
  isPaletteColor,
  isPaletteWithValueColor,
  isStaticColor,
  isVariantColor,
  isVariantWithShadeColor,
  getOptionalThemeColor,
  getThemeColor,
  toColorWithShade,
  withOpacity,
};

export default themeColors;
