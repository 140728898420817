import styled from "styled-components";
import { match } from "ts-pattern";
import PinKey, { PinKeyValue } from "./PinKey";

export interface Props {
  value: string;
  onChange: (pin: string) => void;
}

const PinKeyboard = (props: Props) => {
  const handleClick = (key: PinKeyValue) => {
    return match(key)
      .with("backspace", () => props.onChange(props.value.slice(0, -1)))
      .otherwise(() => props.onChange(props.value + key));
  };

  return (
    <PinKeyboard.Root>
      <PinKey value="1" onClick={handleClick} />
      <PinKey value="2" onClick={handleClick} />
      <PinKey value="3" onClick={handleClick} />
      <PinKey value="4" onClick={handleClick} />
      <PinKey value="5" onClick={handleClick} />
      <PinKey value="6" onClick={handleClick} />
      <PinKey value="7" onClick={handleClick} />
      <PinKey value="8" onClick={handleClick} />
      <PinKey value="9" onClick={handleClick} />
      <div />
      <PinKey value="0" onClick={handleClick} />
      <PinKey value="backspace" onClick={handleClick} />
    </PinKeyboard.Root>
  );
};

PinKeyboard.Root = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 12px;
  justify-content: center;
  justify-items: center;

  .PinKey {
    --size: 16vw;
    --max-size: 65px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: var(--size);
    max-width: var(--max-size);
    height: var(--size);
    max-height: var(--max-size);

    font-size: 20px;
    font-weight: 700;

    border: 1px solid #e6e6e6;
    border-radius: 100px;

    transition: background-color 100ms ease;

    &:active {
      color: ${(props) => props.theme.colors.primary()};

      background-color: ${(props) => props.theme.colors.extra.background1};
      border-color: ${(props) => props.theme.colors.primary()};

      [fill] {
        fill: ${(props) => props.theme.colors.primary()};
      }
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: 10% 10% 10%;
  }
`;

export default PinKeyboard;
