import React from "react";
import styled from "styled-components";
import { baseInputCss } from "./Question";
import YesNo from "../YesNo";

const StyledQuestionLikeDislike = styled.label`
  ${baseInputCss}

  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectionWrapper = styled.div`
  display: flex;
  gap: 36px;
  align-items: center;
  justify-content: end;
`;

const QuestionYesNo = React.forwardRef(
  (
    props: React.ComponentPropsWithRef<typeof YesNo> & {
      children: React.ReactNode;
      className?: string;
    },
    ref: React.Ref<HTMLInputElement>
  ) => {
    const { children, className, ...rest } = props;

    return (
      <StyledQuestionLikeDislike className={className}>
        {children}
        <SelectionWrapper>
          <YesNo {...rest} ref={ref} value={"yes"} data-type="yes" />
          <YesNo {...rest} ref={ref} value={"no"} data-type="no" />
        </SelectionWrapper>
      </StyledQuestionLikeDislike>
    );
  }
);

QuestionYesNo.displayName = "QuestionYesNo";

export default QuestionYesNo;
