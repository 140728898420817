import EventBubble from "./EventBubble";
import PhoneScreenImage from "./PhoneScreenImage";
import { VisitEventListItem, VisitsEventsList } from "./VisitEventsList";

const VisitsCard = () => (
  <>
    <VisitsEventsList bottom={-10}>
      <VisitEventListItem translateX={14} src="/landing/why-join/endless-visits/visit-card-1.png" />
      <VisitEventListItem
        translateX={-10}
        src="/landing/why-join/endless-visits/visit-card-2.png"
      />
      <VisitEventListItem translateX={20} src="/landing/why-join/endless-visits/visit-card-3.png" />
    </VisitsEventsList>
    <PhoneScreenImage />
    <EventBubble delay={5} duration={60} bottom={10} size={30} left={6} text="JK" />
    <EventBubble delay={1} duration={37} bottom={60} size={24} left={12} text="MS" />
    <EventBubble delay={1} duration={47} bottom={40} size={18} left={20} text="ET" />
    <EventBubble delay={3} duration={18} bottom={20} size={20} left={80} text="OW" />
    <EventBubble delay={3} duration={28} bottom={70} size={26} left={96} text="PM" />
  </>
);

export default VisitsCard;
