import styled from "styled-components";
import Stars from "./Stars";
import { useTranslation } from "react-i18next";

const AppRating = () => {
  const { t } = useTranslation();

  return (
    <AppRating.Styled>
      <StarsWithRating>
        <Rating>4.9</Rating>
        <Stars rating={5} size="big" />
      </StarsWithRating>
      <Downloads>{t("home-caregiver-reviews-app-rating-downloads")}</Downloads>
    </AppRating.Styled>
  );
};
AppRating.Styled = styled.div`
  display: flex;
  flex-direction: column;
`;

const StarsWithRating = styled.div`
  display: flex;
  align-items: center;

  margin-top: 8px;

  color: ${(props) => props.theme.colors.static.inverted};
`;

const Rating = styled.div`
  display: flex;
  align-content: center;
  align-items: center;

  margin-right: 10px;

  font-size: 22px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.static.inverted};
`;

const Downloads = styled.div`
  margin-top: 6px;

  font-size: 14px;
  color: ${(props) => props.theme.colors.palette.gray["500"]};
`;

export default AppRating;
