import { TFunction } from "react-i18next";

export interface Step {
  title: string;
  subtitle: string;
}

export const getSteps = (t: TFunction): Step[] => {
  return [
    {
      title: t("home-how-to-start-start-steps-1-title"),
      subtitle: t("home-how-to-start-start-steps-1-subtitle"),
    },
    {
      title: t("home-how-to-start-start-steps-2-title"),
      subtitle: t("home-how-to-start-start-steps-2-subtitle"),
    },
    {
      title: t("home-how-to-start-start-steps-3-title"),
      subtitle: t("home-how-to-start-start-steps-3-subtitle"),
    },
  ];
};
