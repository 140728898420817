import styled from "styled-components";

const SmallText = styled.p`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.palette.gray[400]};
  white-space: nowrap;

  strong {
    font-size: 24px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.static.inverted};
  }
`;

export default SmallText;
