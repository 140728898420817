import styled from "styled-components";

interface Props {
  icon: string;
  enabled: boolean;
  onClick: () => void;
}

const CarouselButton = (props: Props) => {
  return (
    <CarouselButton.Styled enabled={props.enabled} onClick={props.onClick}>
      <img src={props.icon} />
    </CarouselButton.Styled>
  );
};

CarouselButton.Styled = styled.div<{ enabled: boolean }>`
  --size: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--size);
  height: var(--size);
  margin: 0 12px;

  border-radius: var(--size);
  box-shadow: 0px 6px 32px 0px #04142f1f;

  img {
    opacity: ${({ enabled }) => (enabled ? 1 : 0.6)};
  }
`;

export default CarouselButton;
