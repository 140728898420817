import LoadingRoute from "@shared/components/LoadingRoute";
import React from "react";

const OnboardingView = React.lazy(() => import("./OnboardingView"));

const OnboardingRoute = () => {
  return <React.Suspense fallback={<LoadingRoute />}>{<OnboardingView />}</React.Suspense>;
};

export default OnboardingRoute;
