import Button from "@shared/components/Button";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Heading1 from "../../components/Heading1";
import HomeSection from "../../components/HomeSection";
import StartStep from "./StartStep";
import { getSteps } from "./StartStepsData";

interface Props {
  onClickStartOnboarding: () => void;
}

const HowToStartSection = (props: Props) => {
  const { t } = useTranslation();
  const steps = getSteps(t);

  return (
    <HomeSection>
      <Heading>How to start work</Heading>
      {steps.map((step, i) => (
        <StartStep key={i} index={i + 1} step={step} />
      ))}
      <ButtonContainer>
        <Button
          size="large"
          variant="sexy"
          data-track="join-now-btn-how-to-start"
          onClick={props.onClickStartOnboarding}
        >
          {t("become-part-of-the-team")}
        </Button>
      </ButtonContainer>
    </HomeSection>
  );
};

const Heading = styled(Heading1)`
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 12px;
`;

export default HowToStartSection;
