import { zodResolver } from "@hookform/resolvers/zod";
import Form from "@shared/components/Form";
import Question from "@shared/components/Question";
import { StageProps } from "@shared/utils/stageUtils";
import { useForm } from "react-hook-form";

import { z } from "zod";
import QuestionHeading from "@shared/components/QuestionHeading";
import { OnboardingFormData } from "../hooks/useOnboardingFormStorage";
import { useTranslation } from "react-i18next";
import NextButton from "@shared/components/NextButton";

type Props = StageProps<StageFormData, OnboardingFormData> & { isLoading: boolean };

export const stageNameFormSchema = z.object({
  firstName: z.string().min(1),
  middleName: z.string().nullable(),
  lastName: z.string().min(1),
});

type StageFormData = z.infer<typeof stageNameFormSchema>;

const StageName = (props: Props) => {
  const { register, handleSubmit, formState } = useForm<StageFormData>({
    resolver: zodResolver(stageNameFormSchema),
    defaultValues: props.form,
    mode: "onChange",
  });

  const { t } = useTranslation();

  return (
    <Form role="form" onSubmit={handleSubmit(props.onValidSubmit, props.onInvalidSubmit)}>
      <QuestionHeading
        title={t("whats-your-name")}
        subtitle={t("please-enter-your-name-as-it-appears-on-your-id")}
      />

      <Question>
        <Question.Input
          {...register("firstName")}
          placeholder="First name"
          autoFocus={true}
          required
        />
        <Question.Input {...register("middleName")} placeholder={t("middle-name")} />
        <Question.Input {...register("lastName")} placeholder={t("last-name")} required />
      </Question>

      <NextButton trackId="name-btn" isDisabled={!formState.isValid} isLoading={props.isLoading} />
    </Form>
  );
};

export default StageName;
