import React from "react";
import styled from "styled-components";
import Checkbox from "../Checkbox";
import { baseInputCss } from "./Question";
import QuestionWrapperStyled from "./QuestionWrapperStyled";

const StyledQuestionCheckbox = styled.label`
  ${baseInputCss}

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const QuestionCheckbox = React.forwardRef(
  (
    props: React.ComponentPropsWithRef<typeof Checkbox> & {
      children: React.ReactNode;
      className?: string;
    },
    ref: React.Ref<HTMLInputElement>
  ) => {
    const { children, className, ...rest } = props;
    return (
      <QuestionWrapperStyled>
        <StyledQuestionCheckbox className={className}>
          {children}
          <Checkbox {...rest} ref={ref} />
        </StyledQuestionCheckbox>
      </QuestionWrapperStyled>
    );
  }
);

QuestionCheckbox.displayName = "QuestionCheckbox";

export default QuestionCheckbox;
