import styled from "styled-components";

interface Props {
  list: string[];
  size: number;
  iconAlign?: "top" | "center";
}

const Entry = styled.div`
  display: flex;
  align-items: ${(props: Props) => props.iconAlign ?? "center"};

  font-size: 5vw;
`;

const CheckIcon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;

  width: ${(props: Props) => props.size}px;
  height: ${(props: Props) => props.size}px;
  margin-right: 12px;
  padding: 0px;

  font-size: 12px;
  font-weight: 900;
  line-height: 16px;
  color: white;

  background: #46a8ff;
  border-radius: 8px;
`;

const ItemText = styled.div`
  flex: 1;

  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
`;

function CheckList(props: Props) {
  return (
    <CheckList.Styled>
      {props.list.map((item, index) => (
        <Entry {...props} key={index}>
          <CheckIcon {...props}>{index + 1}</CheckIcon>
          <ItemText>{item}</ItemText>
        </Entry>
      ))}
    </CheckList.Styled>
  );
}

CheckList.Styled = styled.div`
  ${Entry} + ${Entry} {
    margin-top: 12px;
  }
`;

export default CheckList;
