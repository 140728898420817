import styled, { keyframes } from "styled-components";
import PhoneScreenImage from "./PhoneScreenImage";

const VisitsCard = () => (
  <>
    <CoinImage src="/landing/why-join/payday/coin.png" />
    <SplashImage src="/landing/why-join/payday/splashes.png" />
    <PhoneScreenImage src={"/landing/why-join/payday/screen.png"} />
  </>
);

const scale = keyframes`
  0% {
    opacity: 0;

    scale: 10%;
  }
  4% {
    opacity: 1;

    scale: 60%;
  }
  50% {
    opacity: 1;

    scale: 200%;
  }
  70% {
    opacity: 0;

    scale: 400%;
  }
  100% {
    opacity: 0;

    scale: 90%;
  }
`;

const SplashImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  animation: ${scale} 10s linear infinite;
`;

const CoinImage = styled.img`
  position: absolute;
  bottom: 28%;
  left: 20%;

  width: 20%;
`;

export default VisitsCard;
