import PhoneScreenImage from "./PhoneScreenImage";
import CalendarDay from "./CalendarDay";
import { VisitEventListItem, VisitsEventsList } from "./VisitEventsList";

const FlexibilityCard = () => (
  <>
    <VisitsEventsList bottom={-36}>
      <VisitEventListItem translateX={-14} src="/landing/why-join/flexibility/card-1.png" />
      <VisitEventListItem translateX={20} src="/landing/why-join/flexibility/card-2.png" />
    </VisitsEventsList>
    <PhoneScreenImage />
    <CalendarDay delay={1} duration={8} bottom={10} left={10} scale={0.8} day={"SAT"} date={16} />
    <CalendarDay delay={2} duration={6} bottom={80} left={5} scale={1} day={"MON"} date={10} />
    <CalendarDay delay={3} duration={4} bottom={30} left={80} scale={0.8} day={"THU"} date={14} />
    <CalendarDay delay={4} duration={2} bottom={90} left={90} scale={1} day={"SUN"} date={17} />
  </>
);

export default FlexibilityCard;
