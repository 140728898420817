import { useHttpApi } from "@shared/hooks/HttpApi/useHttpApi";
import { useToken } from "@shared/hooks/HttpApi/useToken";
import { useInitOnboardingProcess } from "@shared/hooks/useInitOnboardingProcess";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import HomeView from "./HomeView";

const HomeRoute = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const api = useHttpApi();

  const updateOnboardingData = useMutation(api.updateOnboardingData);

  useInitOnboardingProcess();

  const handleClickStartOnboarding = async () => {
    if (token !== null) {
      updateOnboardingData.mutate({ token, data: { completed: false, clickedJoinNow: true } });
      return navigate("onboarding");
    }
  };
  return <HomeView onClickStartOnboarding={handleClickStartOnboarding} />;
};

export default HomeRoute;
