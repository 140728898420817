import { match } from "ts-pattern";

const themeBreakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

export type Breakpoints = typeof themeBreakpoints;
export type Breakpoint = keyof Breakpoints;

const getNextBreakpoint = (breakpoint: Exclude<Breakpoint, "2xl">): Breakpoint => {
  return match(breakpoint)
    .with("sm", () => "md" as const)
    .with("md", () => "lg" as const)
    .with("lg", () => "xl" as const)
    .with("xl", () => "2xl" as const)
    .exhaustive();
};

export const inBreakpoint = (breakpoints: Breakpoints) => ({
  only: (breakpoint: Breakpoint) => {
    const terms: string[] = [`(min-width: ${breakpoints[breakpoint]}px)`];

    if (breakpoint !== "2xl") {
      terms.push(`(max-width: ${breakpoints[getNextBreakpoint(breakpoint)] - 1}px)`);
    }

    return `@media ${terms.join(" and ")}`;
  },
  up: (breakpoint: Breakpoint) => {
    return `@media (min-width: ${breakpoints[breakpoint]}px)`;
  },
  down: (breakpoint: Breakpoint) => {
    return `@media (max-width: ${breakpoints[breakpoint]}px)`;
  },
});

// Used to import for testing
export const __internalThemeBreakpoints = { getNextBreakpoint, inBreakpoint, themeBreakpoints };

export default themeBreakpoints;
