import LoadingRoute from "@shared/components/LoadingRoute";
import {
  OnboardingProcessTokenError,
  OnboardingProcessTokenResponse,
} from "@shared/hooks/HttpApi/HttpApiMessages";
import { useHttpApi } from "@shared/hooks/HttpApi/useHttpApi";
import { useToken } from "@shared/hooks/HttpApi/useToken";
import { either } from "fp-ts";
import { pipe } from "fp-ts/function";
import { RESET } from "jotai/utils";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import useFormStorage from "../OnboardingRoute/hooks/useOnboardingFormStorage";

interface Props {
  campaign: string;
}

const OnboardingCampaignView = (props: Props) => {
  const httpApi = useHttpApi();
  const { setToken } = useToken();
  const { setForm: sync } = useFormStorage();
  const navigate = useNavigate();

  useQuery(
    ["/onboarding/caregivers", props.campaign],
    () => httpApi.startOnboardingProcess({ campaign: props.campaign }),
    {
      onSuccess: handleSuccess,
      onError: handleError,
      onSettled: handleSettled,
    }
  );

  function handleError(error: unknown) {
    console.error(error);
    clearCache();
  }

  function handleSuccess(
    data: either.Either<OnboardingProcessTokenError, OnboardingProcessTokenResponse>
  ) {
    clearCache();

    pipe(
      data,
      either.mapLeft(console.error),
      either.map(({ token }) => setToken(token))
    );

    navigate("/");
  }

  function handleSettled() {
    return navigate("/");
  }

  const clearCache = () => {
    setToken(RESET);
    sync(RESET);
  };

  return <LoadingRoute />;
};

export default OnboardingCampaignView;
