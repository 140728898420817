import HomeSection from "../../components/HomeSection";
import Heading1 from "../../components/Heading1";
import styled from "styled-components";
import VimeoVideo from "@shared/components/VimeoVideo";

const CaregiversSpeakSection = () => {
  return (
    <HomeSection align="center" backgroundColor="#EFF3FB">
      <Heading>
        Caregivers speak <br />
        about us
      </Heading>
      <VimeoVideo videoId="469425373" />
    </HomeSection>
  );
};

const Heading = styled(Heading1)`
  margin-bottom: 8px;

  text-align: center;
`;

export default CaregiversSpeakSection;
