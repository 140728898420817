import Star, { StarProps } from "./Star";
import styled from "styled-components";

interface Props {
  rating: number;
  size: StarProps["size"];
}

const Stars = (props: Props) => {
  return (
    <Stars.Styled>
      {[1, 2, 3, 4, 5].map((rate) => (
        <Star key={rate} size={props.size} appearance={rate > props.rating ? "outline" : "fill"} />
      ))}
    </Stars.Styled>
  );
};

Stars.Styled = styled.div`
  display: flex;
`;

export default Stars;
