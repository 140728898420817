import Form from "@shared/components/Form";
import Logo from "@shared/components/Logo";
import QuestionHeading from "@shared/components/QuestionHeading";
import Section from "@shared/components/Section";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

function NotCertifiedView() {
  const { t } = useTranslation();

  return (
    <Section>
      <Logo />
      <Form role="form" style={{ paddingTop: 50 }}>
        <QuestionHeading title={t("not-certified-title")} subtitle={t("not-certified-message")} />

        <KeepInTouchImg src="/keep-in-touch.svg" alt="keep-in-touch" />
      </Form>
    </Section>
  );
}

const KeepInTouchImg = styled.img`
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 100px;

  width: 80%;
`;

export default NotCertifiedView;
