import { useToken } from "@shared/hooks/HttpApi/useToken";
import { Navigate, useParams } from "react-router-dom";
import OnboardingCampaignView from "./CampaignView";

const CampaignRoute = () => {
  const params = useParams<{ campaign: string }>();
  const { token } = useToken();

  if (params.campaign === undefined || token !== null) {
    console.log("redirecting to home", { ...params, token });
    return <Navigate to={"/"} />;
  }

  return <OnboardingCampaignView campaign={params.campaign} />;
};

export default CampaignRoute;
