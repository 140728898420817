import { useAutoAnimate } from "@formkit/auto-animate/react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Dialog from "@radix-ui/react-dialog";
import CloseButton from "@shared/components/CloseButton";
import Form from "@shared/components/Form";
import NextButton from "@shared/components/NextButton";
import Prediction from "@shared/components/Prediction";
import Question from "@shared/components/Question";
import QuestionHeading from "@shared/components/QuestionHeading";
import { GOOGLE_MAPS_API_KEY } from "@shared/utils/constants";
import { StageProps } from "@shared/utils/stageUtils";
import { fromPlaceId, toMfAddressComponents } from "mf-google-geocoder";
import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { z } from "zod";
import useGoogleMapsAddress from "../hooks/useGoogleMapsAddress";
import { OnboardingFormData } from "../hooks/useOnboardingFormStorage";

type Props = StageProps<StageFormData, OnboardingFormData> & { isLoading: boolean };

const Location = z.object({
  lat: z.number(),
  lng: z.number(),
});

const FormatedAddressDetails = z.object({
  location: Location,
  address1: z.string(),
  address2: z.string().nullable().optional(),
  country: z.string(),
  state: z.string(),
  county: z.string(),
  fullAddress: z.string(),
  city: z.string().nullable().optional(),
  zip5: z.string().nullable().optional(),
  zip4: z.string().nullable().optional(),
  street: z.string().nullable().optional(),
  streetNumber: z.string().nullable().optional(),
  crossStreet: z.string().nullable().optional(),
});

const AddressComponent = z.object({
  location: Location,
  country: z.string(),
  administrativeAreaLevel1: z.string(),
  administrativeAreaLevel2: z.string(),
  sublocalityLevel1: z.string().nullable().optional(),
  neighborhood: z.string().nullable().optional(),
  locality: z.string().nullable().optional(),
  formatedAddressDetails: FormatedAddressDetails.optional(),
});

export const stageAddressFormSchema = (errorMessages?: { address: string }) =>
  z.object({
    address: z.string({ required_error: errorMessages?.address }).min(2),
    address2: z.string().nullable(),
    addressComponents: AddressComponent.nullable().optional(),
    isTestUser: z.boolean().optional(),
  });

type StageFormData = z.infer<ReturnType<typeof stageAddressFormSchema>>;

const Predictions = styled.div``;

const ADDRESS_MODAL_SEARCH_PARAMS = "address-modal";

const StageAddress = (props: Props) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [parent] = useAutoAnimate<HTMLDivElement>();
  const navigate = useNavigate();
  const [titleTapCount, setTitleTapCount] = React.useState(0);
  const { register, control, handleSubmit, setValue, reset, formState } = useForm<StageFormData>({
    resolver: zodResolver(stageAddressFormSchema({ address: t("address-is-required") })),
    defaultValues: {
      ...props.form,
      address: props.form.address ?? "",
      address2: props.form.address2,
    },
    mode: "onTouched",
  });

  const isTestUser = titleTapCount >= 10;
  const addressComponentsInput = useWatch({ control, name: "addressComponents" });

  const {
    handleChange: handleChangeAddress,
    placePredictions,
    input,
    getDetails,
    showPredictions,
    setShowPredictions,
  } = useGoogleMapsAddress();

  const handleClickPrediction = (prediction: google.maps.places.AutocompletePrediction) => {
    setShowPredictions(false);
    reset({ address: prediction.description, addressComponents: null });
    getDetails(prediction).then((result) => {
      const placeId = result?.place_id;

      if (placeId === undefined) {
        return;
      }

      fromPlaceId(placeId, {
        apiKey: GOOGLE_MAPS_API_KEY,
        mfAutoFix: true,
      })
        .then((addressDetails) => {
          setValue("addressComponents", toMfAddressComponents(addressDetails));
        })
        .catch((e) => {
          console.error("Error getting address details", e);
          setValue("addressComponents", null);
        });
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeAddress(event);
    setValue("address", event.target.value);
    setValue("addressComponents", undefined);

    if (event.target.value === "") {
      handleClickClose();
      return;
    }

    if (!searchParams.has(ADDRESS_MODAL_SEARCH_PARAMS)) {
      navigate({ search: ADDRESS_MODAL_SEARCH_PARAMS });
    }
  };

  const handleClickClose = () => {
    reset({ address: "", addressComponents: null });
    navigate({ search: undefined });
  };

  const isDialogOpen =
    searchParams.has(ADDRESS_MODAL_SEARCH_PARAMS) && addressComponentsInput === undefined;

  const handleValidSubmit = (data: StageFormData) => {
    if (data.addressComponents?.administrativeAreaLevel1 !== "NY") {
      return navigate("/onboarding/region-unavailable");
    }

    props.onValidSubmit({
      ...data,
      isTestUser: isTestUser,
    });
  };

  return (
    <Form
      role="form"
      onSubmit={handleSubmit(handleValidSubmit, props.onInvalidSubmit)}
      autoComplete="off"
    >
      <div onClick={() => setTitleTapCount((count) => count + 1)}>
        <QuestionHeading
          title={t("where-do-you-live")}
          subtitle={t("we-want-to-find-you-the-best-cases-near-you")}
        />
      </div>

      <Question>
        <Question.Input
          {...register("address", { onChange: handleChange })}
          placeholder={t("address")}
          autoFocus={true}
          required={true}
          autoComplete="off"
        />
        <Question.Input
          {...register("address2")}
          placeholder={t("address-2-placeholder")}
          required={false}
        />
      </Question>

      <Dialog.Root open={isDialogOpen}>
        <Dialog.Portal>
          <StyledContent>
            <Question>
              <Question.Input
                {...register("address", { onChange: handleChange })}
                placeholder={t("address")}
                autoFocus={true}
                required={true}
                autoComplete="off"
                trailing={
                  <Dialog.Close asChild>
                    <CloseButton onClick={handleClickClose} />
                  </Dialog.Close>
                }
              />
            </Question>
            {showPredictions && (
              <Predictions ref={parent}>
                {placePredictions.map((prediction) => (
                  <Prediction
                    key={prediction.place_id}
                    prediction={prediction}
                    query={input}
                    onClick={handleClickPrediction}
                  />
                ))}
              </Predictions>
            )}
          </StyledContent>
        </Dialog.Portal>
      </Dialog.Root>

      <NextButton
        trackId="address-btn"
        isDisabled={!formState.isValid}
        isLoading={props.isLoading}
        buttonText={isTestUser ? "Sign up as a test user" : t("sign-up")}
      />
    </Form>
  );
};

const StyledContent = styled(Dialog.Content)`
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.DialogContent};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  height: 100%;

  background-color: ${(props) => props.theme.colors.static.neutral};

  ${Question} {
    margin: 16px;
  }

  ${Prediction.Styled} {
    padding: 16px 24px;

    :hover {
      cursor: pointer;

      color: ${(props) => props.theme.colors.static.neutral};

      background-color: ${(props) => props.theme.colors.primary()};
    }

    + ${Prediction.Styled} {
      border-top: 1px ${(props) => props.theme.colors.palette.gray[100]} solid;
    }
  }
`;

export default StageAddress;
