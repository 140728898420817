import React from "react";
import styled from "styled-components";
import { baseInputCss } from "./Question";
import QuestionWrapperStyled from "./QuestionWrapperStyled";
import Radio from "./Radio";

const StyledQuestionRadio = styled.label`
  ${baseInputCss}

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const QuestionRadio = React.forwardRef(
  (
    props: React.ComponentPropsWithRef<typeof Radio> & Omit<JSX.IntrinsicElements["input"], "type">,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const { children, className, ...rest } = props;
    return (
      <QuestionWrapperStyled>
        <StyledQuestionRadio className={className}>
          {children}
          <Radio {...rest} ref={ref} />
        </StyledQuestionRadio>
      </QuestionWrapperStyled>
    );
  }
);

QuestionRadio.displayName = "QuestionRadio";

export default QuestionRadio;
