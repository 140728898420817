import Heading2 from "../../../components/Heading2";
import SubHeading from "../../../components/SubHeading";
import styled from "styled-components";

interface Props {
  title: string;
  subtitle: string;
  children: React.ReactNode;
}

const BenefitCard = (props: Props) => {
  return (
    <BenefitCard.Styled>
      <Heading>{props.title}</Heading>
      <SubHeading>{props.subtitle}</SubHeading>
      {props.children}
    </BenefitCard.Styled>
  );
};

BenefitCard.Styled = styled.div`
  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 24px;

  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 6px 48px rgba(4, 20, 47, 0.08);

  ${SubHeading} {
    text-align: center;
    white-space: break-spaces;
  }
`;

const Heading = styled(Heading2)`
  margin-top: 20px;
  margin-bottom: 8px;

  text-align: center;
`;

export default BenefitCard;
