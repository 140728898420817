import ErrorRoute from "@shared/components/ErrorRoute";
import LoadingRoute from "@shared/components/LoadingRoute";
import { useHttpApi } from "@shared/hooks/HttpApi/useHttpApi";
import matchUtils from "@shared/utils/matchUtils";
import { QueryStatus } from "@shared/utils/queryUtils";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { match } from "ts-pattern";

const ReviewView = React.lazy(() => import("./ReviewView"));

const ReviewRoute = () => {
  const api = useHttpApi();
  const [params] = useSearchParams();

  const token = params.get("token");

  const query = useQuery("review-data", () => api.getI9ReviewerData({ token: token as string }), {
    enabled: token !== null,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return match(query)
    .with(QueryStatus.Idle, () => <ErrorRoute error={"Token was not provided"} />)
    .with(QueryStatus.Loading, () => <LoadingRoute />)
    .with(QueryStatus.Error, (error) => <ErrorRoute error={error} />)
    .with(QueryStatus.Resolved, (data) =>
      match(data)
        .with(matchUtils.either.isLeft, (error) => <ErrorRoute error={error} />)
        .with(matchUtils.either.isRight, ({ data }) => (
          <React.Suspense fallback={<LoadingRoute />}>{<ReviewView {...data} />}</React.Suspense>
        ))
        .exhaustive()
    )
    .exhaustive();
};

export default ReviewRoute;
