import { t } from "i18next";
import { Control, Controller } from "react-hook-form";
import PhoneInput from "react-phone-number-input/input";
import styled from "styled-components";
import { baseInputCss } from "./Question";
import QuestionWrapperStyled from "./QuestionWrapperStyled";

interface Props {
  isDisabled: boolean;
  control: Control<
    {
      phoneNumber: string;
    },
    unknown
  >;
}

const StyledPhoneInput = styled(PhoneInput)`
  ${baseInputCss}
`;

function QuestionPhoneNumberInput(props: Props) {
  return (
    <QuestionWrapperStyled>
      <Controller
        render={({ field }) => (
          <StyledPhoneInput
            country="US"
            disabled={props.isDisabled}
            placeholder={t("phone-number")}
            autoFocus={true}
            aria-label={"phoneNumber"}
            role="textbox"
            maxLength={14}
            {...field}
          />
        )}
        control={props.control}
        name="phoneNumber"
      />
    </QuestionWrapperStyled>
  );
}

export default QuestionPhoneNumberInput;
