import styled, { css } from "styled-components";
import QuestionWrapperStyled from "./QuestionWrapperStyled";

const Question = styled.div`
  display: block;
  flex-grow: 1;

  margin-bottom: 12px;

  ${QuestionWrapperStyled} + ${QuestionWrapperStyled} {
    margin-top: 10px;
  }
`;

export const baseInputCss = css`
  width: 100%;
  padding: 4px;
  padding: 18px 22px;

  font-size: 22px;
  color: ${(props) => props.theme.colors.palette.gray[700]};

  ::placeholder,
  :invalid {
    color: ${(props) => props.theme.colors.palette.gray[400]};
  }
`;

export default Question;
