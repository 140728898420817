import { withOpacity } from "@shared/theme/theme.colors";
import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";

const pulse = keyframes`
    0% {
      transform: scale(0.5);

      opacity: 1;
    }
    50% {
      transform: scale(1);

      opacity: 0.5;
    }
    100% {
      transform: scale(0.5);

      opacity: 1;
    }
`;

const Circle = styled.div<{ size: number }>`
  position: absolute;

  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  padding: 5px;

  background: ${(props) => withOpacity(props.theme.colors.primary(), 0.15)};
  border-radius: 100%;

  animation: ${pulse} 3s ease-in-out infinite;
`;

function MapCircle(props: { delay: number; interval: number }) {
  return (
    <MapCircle.Styled
      initial={{ opacity: 0, transform: "scale(0.5)" }}
      animate={{ opacity: 1, transform: "scale(1)" }}
      transition={{
        duration: 0.6,
        type: "spring",
        delay: props.delay,
        repeat: Infinity,
        repeatType: "reverse",
        repeatDelay: props.interval,
      }}
    >
      <Circle size={60} />
      <Circle size={40} />
      <Circle size={20} />
    </MapCircle.Styled>
  );
}

MapCircle.Styled = styled(motion.div)`
  --size: 60px;

  position: relative;
  z-index: ${(props) => props.theme.zIndex.Circles};

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--size);
  height: var(--size);
`;

export default MapCircle;
