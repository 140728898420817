import HomeSection from "../../components/HomeSection";
import Heading1 from "../../components/Heading1";
import CaregiverTestimonialsCarousel from "./CaregiverTestimonialsCarousel/CaregiverTestimonialsCarousel";
import { useTranslation } from "react-i18next";
import Button from "@shared/components/Button";
import styled from "styled-components";

interface Props {
  onClickStartOnboarding: () => void;
}

function CaregiverTestimonialsSection(props: Props) {
  const { t } = useTranslation();

  return (
    <HomeSection>
      <Heading1>{t("home-caregivers-testimonials-title")}</Heading1>
      <CaregiverTestimonialsCarousel />
      <ButtonContainer>
        <Button
          size="large"
          variant="sexy"
          data-track="join-now-btn-last"
          onClick={props.onClickStartOnboarding}
        >
          {t("home-apply-now")}
        </Button>
      </ButtonContainer>
    </HomeSection>
  );
}

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 12px;
`;

export default CaregiverTestimonialsSection;
