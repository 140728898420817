import React from "react";
import styled from "styled-components";
import IconButton from "./IconButton";

function CloseButton(
  props: React.ComponentPropsWithRef<typeof CloseButton.Styled>,
  ref: React.Ref<HTMLButtonElement>
) {
  return <CloseButton.Styled src="/close.svg" {...props} ref={ref} />;
}

CloseButton.Styled = styled(IconButton)``;

export default React.forwardRef(CloseButton);
