import styled from "styled-components";

interface Props {
  align?: "left" | "center";
}

const SubHeading = styled.p<Props>`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.palette.gray["500"]};
  text-align: ${(props) => props.align ?? "left"};
`;

export default SubHeading;
