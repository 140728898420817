import React from "react";
import styled from "styled-components";
import { baseInputCss } from "./Question";
import QuestionWrapper from "./QuestionWrapperStyled";

const StyledQuestionInput = styled.input`
  ${baseInputCss}
`;

const StyledQuestionInputTrailing = styled.div`
  padding: 22px;
`;

const BaseQuestionInput = React.forwardRef(
  (
    props: React.ComponentPropsWithRef<typeof StyledQuestionInput> &
      JSX.IntrinsicElements["input"] & { trailing?: React.ReactNode },
    ref: React.Ref<typeof StyledQuestionInput>
  ) => {
    return (
      <QuestionWrapper data-disabled={props.disabled}>
        <StyledQuestionInput role="textbox" aria-label={props.name} ref={ref} {...props} />
        {props.trailing !== undefined && (
          <StyledQuestionInputTrailing>{props.trailing}</StyledQuestionInputTrailing>
        )}
      </QuestionWrapper>
    );
  }
);

BaseQuestionInput.displayName = "BaseQuestionInput";

const QuestionInput = Object.assign(BaseQuestionInput, {
  Styled: StyledQuestionInput,
});

export default QuestionInput;
