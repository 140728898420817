import styled from "styled-components";

const YesNo = styled.input`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 120px;
  height: 60px;
  padding: 32px;

  background: ${(props) => props.theme.colors.static.neutral};
  border: 1px solid #d0d5dd;
  border-radius: 12px;

  &[data-type="yes"]:checked {
    color: ${(props) => props.theme.colors.primary()};

    background: #eff8ff;
    border: 1px solid ${(props) => props.theme.colors.primary()};
  }

  &[data-type="no"]:checked {
    color: ${(props) => props.theme.colors.negative()};

    background: #fce6e6;
    border: 1px solid ${(props) => props.theme.colors.negative()};
  }

  &[data-type="yes"]:before {
    content: "Yes";
  }
  &[data-type="no"]:before {
    content: "No";
  }

  @media (min-width: 768px) {
    width: 70px;
    height: 50px;
  }
`;

YesNo.defaultProps = { type: "radio", role: "radio" };

export default YesNo;
