import styled from "styled-components";
import { Testimonial } from "./CaregiverTestimonialsData";

interface Props {
  testimonial: Testimonial;
}

const CaregiverTestimonial = ({ testimonial: { image, name, text } }: Props) => (
  <CaregiverTestimonial.Styled>
    <TestimonialImage src={image} />
    <TestimonialTextContainer>
      <TestimonialCaregiverName>{name}</TestimonialCaregiverName>
      <TestimonialText>{text}</TestimonialText>
    </TestimonialTextContainer>
  </CaregiverTestimonial.Styled>
);

CaregiverTestimonial.Styled = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;

  width: 90vw;
  min-width: 90vw;
  margin-right: 12px;
  padding: 0px 0px 16px;

  background: #eff3fb;
  border-radius: 12px;

  &:last-child {
    margin-right: unset;
  }
`;

const TestimonialImage = styled.img`
  width: 100%;
`;
const TestimonialTextContainer = styled.div`
  padding: 16px;
`;

const TestimonialCaregiverName = styled.div`
  font-size: 14px;
  font-weight: 900;
  line-height: 140%;
`;
const TestimonialText = styled.div`
  margin-top: 4px;

  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  color: ${(props) => props.theme.colors.palette.gray["500"]};
`;

export default CaregiverTestimonial;
