import styled from "styled-components";

const Radio = styled.input`
  width: 32px;
  height: 32px;

  border: 2px solid ${({ theme }) => theme.colors.palette.gray[50]};
  border-radius: 100%;

  &:checked {
    background: ${(props) => props.theme.colors.primary()};
    border: none;
  }
`;

Radio.defaultProps = { type: "radio", role: "radio" };

export default Radio;
