import { StageProps } from "@shared/utils/stageUtils";
import QuestionHeading from "@shared/components/QuestionHeading";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { OnboardingFormData } from "../hooks/useOnboardingFormStorage";
import Question from "@shared/components/Question";
import styled from "styled-components";
import React from "react";
import PinKeyboard from "@shared/components/PinKeyboard";
import { formatPhoneNumber } from "react-phone-number-input";

type Props = StageProps<StageFormData, OnboardingFormData> & { isLoading: boolean };
type StageFormData = z.infer<ReturnType<typeof stageSMSVerificationFormSchema>>;

export const stageSMSVerificationFormSchema = () =>
  z.object({
    code: z.string().length(5),
  });

const StageSMSVerification = (props: Props) => {
  const { t } = useTranslation();
  const [code, setCode] = React.useState("");

  function handleChange(newCode: string): void {
    if (/^\d{0,5}$/.test(newCode)) {
      setCode(newCode);
    }

    if (newCode.length === 5) {
      props.onValidSubmit({ code: newCode });
      setTimeout(() => {
        setCode("");
      }, 500);
    }
  }

  return (
    <>
      <QuestionHeading
        title={t("hi-caregiver", {
          caregiverName: props.form.firstName,
        })}
        subtitle={t("please-enter-the-verification-code-we-sent-to", {
          phoneNumber: formatPhoneNumber(props.form.phoneNumber ?? ""),
        })}
      />
      <StageSMSVerification.Styled>
        <Question>
          <Question.SmsCodeInput
            inputProps={{
              value: code,
              onChange: (e) => handleChange(e.target.value),
            }}
          />
          <InfoText>
            {t("you-will-get-a-different-verification-code-when-you-log-into-the-app")}
          </InfoText>
        </Question>
        <StageSMSVerification.KeyboardWrapper isLoading={props.isLoading}>
          <PinKeyboard value={code} onChange={handleChange} />
        </StageSMSVerification.KeyboardWrapper>
      </StageSMSVerification.Styled>
    </>
  );
};

StageSMSVerification.KeyboardWrapper = styled.div<{ isLoading: boolean }>`
  position: relative;

  ${PinKeyboard.Root} {
    opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};

    transition: opacity 0.2s;
  }
`;

StageSMSVerification.Styled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 0 56px;

  ${PinKeyboard.Root} {
    margin: 12px 0;
  }

  ${Question.SmsCodeInput.Root} {
    margin: 16px 0;
  }

  ${Question.SmsCodeInput.Root}

  @media (max-width: 375px) {
    margin-top: 24px;
  }
`;

const InfoText = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.palette.gray[400]};
  text-align: center;
`;

export default StageSMSVerification;
