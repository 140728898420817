import { TFunction } from "react-i18next";
import VisitsCard from "./Cards/VisitsCard";
import PayDayCard from "./Cards/PayDayCard";
import FlexibilityCard from "./Cards/FlexibilityCard";
import BenefitsInAppCard from "./Cards/BenefitsInAppCard";

interface Benefit {
  title: string;
  subtitle: string;
  card: React.ReactNode;
}

export const getBenefits = (t: TFunction): Benefit[] => {
  return [
    {
      title: t("home-join-medflyt-benefits-1-title"),
      subtitle: t("home-join-medflyt-benefits-1-subtitle"),
      card: <VisitsCard />,
    },
    {
      title: t("home-join-medflyt-benefits-2-title"),
      subtitle: t("home-join-medflyt-benefits-2-subtitle"),
      card: <PayDayCard />,
    },
    {
      title: t("home-join-medflyt-benefits-3-title"),
      subtitle: t("home-join-medflyt-benefits-3-subtitle"),
      card: <FlexibilityCard />,
    },
    {
      title: t("home-join-medflyt-benefits-4-title"),
      subtitle: t("home-join-medflyt-benefits-4-subtitle"),
      card: <BenefitsInAppCard />,
    },
  ];
};
