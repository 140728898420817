import Bubble from "./Bubble";
import { useTranslation } from "react-i18next";
import MapImage from "./MapImage";

const CaregiversMap = () => {
  const { t } = useTranslation();

  return (
    <>
      <Bubble
        title={t("pca-visit")}
        imageUrl="/patient01.jpg"
        payRate={19}
        delay={2}
        bottom={"32vh"}
        right={20}
        interval={2.5}
      />
      <Bubble
        title={t("hha-visit")}
        imageUrl="/patient02.jpg"
        payRate={20}
        delay={2.5}
        bottom={"44vh"}
        left={66}
        interval={2}
      />
      <Bubble
        title={t("hha-visit")}
        imageUrl="/patient03.jpg"
        payRate={20}
        delay={5.5}
        bottom={"24vh"}
        left={8}
        interval={3}
      />
      <MapImage
        transition={{ duration: 2, type: "tween", ease: "anticipate" }}
        style={{ originX: 0.1, originY: 0.1 }}
        initial={{ opacity: 0, scale: 2 }}
        animate={{ opacity: 1, scale: 1.1 }}
      />
    </>
  );
};

export default CaregiversMap;
