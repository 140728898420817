import React from "react";
import { ReactComponent as BackspaceIcon } from "/public/backspace.svg";

export type PinKeyValue = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "0" | "backspace";

interface Props {
  value: PinKeyValue;
  onClick: (value: PinKeyValue) => void;
}

const PinKey = (props: Props) => {
  return (
    <div className="PinKey" onClick={() => props.onClick(props.value)}>
      {props.value === "backspace" ? <BackspaceIcon /> : <span>{props.value}</span>}
    </div>
  );
};

export default React.memo(PinKey);
