import Logo from "@shared/components/Logo";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CheckList from "./CheckList";
import Heading1 from "../../../components/Heading1";
import SmallText from "./SmallText";

const Content = () => {
  const { t } = useTranslation();
  const features: string[] = [
    t("home-main-features-1"),
    t("home-main-features-2"),
    t("home-main-features-3"),
  ];

  return (
    <ContentContainer>
      <Logo />
      <Heading1>{t("home-main-title")}</Heading1>
      <CheckList size={28} list={features} />
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  width: 100%;
  padding: 4vh 8vw;

  ${Heading1} {
    margin: 12px 0 16px;
  }

  ${SmallText} {
    margin-bottom: 10px;

    text-align: center;
  }
`;

export default Content;
