import React from "react";
import styled from "styled-components";
import { baseInputCss } from "./Question";
import QuestionWrapperStyled from "./QuestionWrapperStyled";

const StyledQuestionSelect = styled.select`
  ${baseInputCss}
`;

const QuestionSelect = React.forwardRef(
  (
    props: React.ComponentPropsWithRef<typeof StyledQuestionSelect> & {
      children: React.ReactNode;
      className?: string;
    },
    ref: React.Ref<HTMLInputElement>
  ) => {
    const { children, ...rest } = props;
    return (
      <QuestionWrapperStyled>
        <StyledQuestionSelect role="combobox" {...rest} ref={ref}>
          {children}
        </StyledQuestionSelect>
      </QuestionWrapperStyled>
    );
  }
);

QuestionSelect.displayName = "QuestionSelect";

const QuestionSelectOption = styled.option``;

export default Object.assign(QuestionSelect, { Option: QuestionSelectOption });
