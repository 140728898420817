import styled from "styled-components";
import { ReactComponent as ClockSvg } from "/public/clock.svg";

const LoadingRoute = () => {
  return (
    <LoadingRoute.Styled>
      <ClockSvg />
    </LoadingRoute.Styled>
  );
};

LoadingRoute.Styled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  svg {
    height: 72px;

    color: ${(props) => props.theme.colors.primary()};

    animation: ${(props) => props.theme.animations.rotate} 1s linear infinite;
  }
`;

export default LoadingRoute;
