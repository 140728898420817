import styled from "styled-components";

const Heading1 = styled.h1`
  font-size: 28px;
  font-weight: 900;
  line-height: 128%;
  color: ${(props) => props.theme.colors.static.inverted};
`;

export default Heading1;
