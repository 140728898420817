import { withOpacity } from "@shared/theme/theme.colors";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MapCircle from "./MapCircle";
import Avatar from "../../../components/Avatar";

interface Props {
  title: string;
  imageUrl: string;
  payRate: number;
  delay: number;
  top?: number | string;
  bottom?: number | string;
  left?: number | string;
  right?: number | string;
  interval: number;
}

function Bubble(props: Props) {
  const { t } = useTranslation();

  return (
    <Bubble.Styled {...props}>
      <Bubble.Inner
        style={{
          originX: 1,
          originY: 1,
        }}
        transition={{
          duration: 0.6,
          delay: props.delay,
          type: "spring",
          repeat: Infinity,
          repeatType: "reverse",
          repeatDelay: props.interval,
        }}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        {...props}
      >
        <Bubble.Content>
          <Avatar size={40} {...props} />
          <Bubble.Message>
            <Bubble.Title>{props.title}</Bubble.Title>
            <Bubble.PayRate>
              ${props.payRate}/{t("hour")}
            </Bubble.PayRate>
          </Bubble.Message>
        </Bubble.Content>
      </Bubble.Inner>
      <MapCircle delay={props.delay} interval={props.interval} />
    </Bubble.Styled>
  );
}

Bubble.Content = styled.div`
  display: flex;
  align-items: center;

  padding: 8px;
`;

Bubble.Title = styled.h4`
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: 20px;
`;

Bubble.PayRate = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${(props) => props.theme.colors.palette.gray[500]};
`;

Bubble.Message = styled.div`
  margin-left: 8px;
  padding-right: 8px;
`;

Bubble.Inner = styled(motion.div)`
  position: relative;

  font-size: 16px;
  color: ${(props) => props.theme.colors.palette.gray[800]};

  background: ${(props) => props.theme.colors.static.neutral};
  border-radius: 300px;
  box-shadow: 0px 2px 5px ${(props) => withOpacity(props.theme.colors.palette.gray[800], 0.15)};

  &:before {
    content: "";

    position: absolute;
    top: calc(100% - 1px);
    right: 2.5em;
    transform: rotate(180deg);

    width: 0;
    height: 0;

    filter: drop-shadow(
      0 -0.0625rem 0.0625rem ${(props) => withOpacity(props.theme.colors.static.inverted, 0.1)}
    );
    border: 0.75rem solid transparent;
    border-top: none;
    border-bottom-color: ${(props) => props.theme.colors.static.neutral};
  }
`;

const getPosition = (position: number | string | undefined) =>
  position === undefined || typeof position === "string" ? position : `${position}px`;

Bubble.Styled = styled(motion.div)`
  position: absolute;
  top: ${(props: Props) => getPosition(props.top)};
  right: ${(props: Props) => getPosition(props.right)};
  bottom: ${(props: Props) => getPosition(props.bottom)};
  left: ${(props: Props) => getPosition(props.left)};

  ${Bubble.Inner} {
    z-index: ${(props) => props.theme.zIndex.Bubble};
  }

  ${MapCircle.Styled} {
    position: absolute;
    top: 36px;
    right: 22px;
  }
`;

export default Bubble;
