import { zodResolver } from "@hookform/resolvers/zod";
import Form from "@shared/components/Form";
import Question from "@shared/components/Question";
import { StageProps } from "@shared/utils/stageUtils";
import { useForm } from "react-hook-form";

import { z } from "zod";
import QuestionHeading from "@shared/components/QuestionHeading";
import { OnboardingFormData } from "../hooks/useOnboardingFormStorage";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import NextButton from "@shared/components/NextButton";

type Props = StageProps<StageFormData, OnboardingFormData> & {
  isLoading: boolean;
  onNotCertified: (form: StageFormData) => void;
};

export const stageCertificationsFormSchema = (errorMessages?: { certifications: string }) =>
  z.object({
    certifications: z.array(z.string()).min(1, errorMessages?.certifications),
  });

type StageFormData = z.infer<ReturnType<typeof stageCertificationsFormSchema>>;

const StageCertifications = (props: Props) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue, formState } = useForm<StageFormData>({
    resolver: zodResolver(
      stageCertificationsFormSchema({ certifications: t("at-least-one-certification-is-required") })
    ),
    defaultValues: { ...props.form, certifications: props.form.certifications ?? [] },
    mode: "onChange",
  });

  const [isNotCertified, setIsNotCertified] = useState(false);

  const handleValidSubmit = (data: StageFormData) => {
    if (!data.certifications.some((cert) => ["HHA", "PCA"].includes(cert))) {
      return props.onNotCertified(data);
    }

    props.onValidSubmit(data);
  };

  const handleCertificationChange = (event: { target: unknown; type?: unknown }) => {
    setIsNotCertified(false);
    inputProps.onChange(event);
  };

  const handleNotCertifiedChange = (event: { target: unknown; type?: unknown }) => {
    if (!isNotCertified) {
      setValue("certifications", ["not_certified"]);
    }

    setIsNotCertified(!isNotCertified);
    inputProps.onChange(event);
  };

  const inputProps = register("certifications");

  return (
    <Form role="form" onSubmit={handleSubmit(handleValidSubmit, props.onInvalidSubmit)}>
      <QuestionHeading
        title={t("what-certifications-do-you-have")}
        subtitle={t("select-all-that-apply")}
      />

      <Question>
        <Question.Checkbox {...inputProps} onChange={handleCertificationChange} value="HHA">
          HHA
        </Question.Checkbox>
        <Question.Checkbox {...inputProps} onChange={handleCertificationChange} value="PCA">
          PCA
        </Question.Checkbox>
        <Question.Checkbox {...inputProps} onChange={handleCertificationChange} value="Other">
          {t("other")}
        </Question.Checkbox>
        <Question.Checkbox
          {...inputProps}
          onChange={handleNotCertifiedChange}
          checked={isNotCertified}
          value="not_certified"
        >
          {t("not-certified")}
        </Question.Checkbox>
      </Question>

      <NextButton
        trackId="certifications-btn"
        isDisabled={!formState.isValid}
        isLoading={props.isLoading}
      />
    </Form>
  );
};

export default StageCertifications;
