import styled from "styled-components";
import { motion } from "framer-motion";

interface Props {
  day: string;
  date: number;
  scale: number;
  delay: number;
  duration: number;
  left: number;
  bottom: number;
}

const CalendarDay = (props: Props) => (
  <CalendarDay.Styled
    {...props}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{
      repeat: Infinity,
      repeatDelay: props.delay,
      repeatType: "reverse",
      type: "spring",
      duration: props.duration,
    }}
  >
    <Day>{props.day.toUpperCase()}</Day>
    <Date>{props.date}</Date>
  </CalendarDay.Styled>
);

CalendarDay.Styled = styled(motion.div)<Props>`
  position: absolute;
  bottom: ${(props) => props.bottom}%;
  left: ${(props) => props.left}%;
  transform: scale(${(props) => props.scale});

  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${(props) => props.theme.colors.palette.gray["200"]};
`;

const Day = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
`;

const Date = styled.div`
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
`;

export default CalendarDay;
