import { motion } from "framer-motion";
import React from "react";
import styled, { css, keyframes } from "styled-components";
import { match } from "ts-pattern";

type ButtonSize = "small" | "medium" | "large";

export type ButtonVariant = "primary" | "secondary" | "sexy";

type Props = React.ComponentPropsWithRef<typeof StyledButton | typeof motion.button> & {
  size?: ButtonSize;
  variant?: ButtonVariant;
};

const cssBySize = (props: { size: ButtonSize }) => {
  return match(props.size)
    .with("small", () => {
      return css`
        padding: 6px 4px;

        font-size: 14px;

        border-radius: 4px;
      `;
    })
    .with("medium", () => {
      return css`
        padding: 12px 8px;

        font-size: 16px;

        border-radius: 8px;
      `;
    })
    .with("large", () => {
      return css`
        padding: 21px 16px;

        font-size: 18px;

        border-radius: 16px;
      `;
    })
    .exhaustive();
};

const gradient = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const cssByVariant = ({ variant }: { variant: ButtonVariant }) => {
  return match(variant)
    .with("primary", () => {
      return css`
        color: ${(props) => props.theme.colors.static.neutral};

        background-color: ${(props) => props.theme.colors.primary()};

        :hover {
          background-color: ${(props) => props.theme.colors.primary(600)};
        }
      `;
    })
    .with("secondary", () => {
      return css`
        color: ${(props) => props.theme.colors.static.inverted};

        background-color: ${(props) => props.theme.colors.palette.gray[200]};
      `;
    })
    .with("sexy", () => {
      return css`
        color: ${(props) => props.theme.colors.static.neutral};

        background-image: linear-gradient(
          -45deg,
          #8910e8 0%,
          #0a77e6 33.33%,
          #0d76e6 66.66%,
          #940dff 100%
        );
        background-size: 400% 400%;

        animation: ${gradient} 10s infinite linear;
      `;
    })
    .exhaustive();
};

const StyledButton = styled(motion.button)<{ size: ButtonSize; variant: ButtonVariant }>`
  ${cssBySize}
  ${cssByVariant}

  display: block;

  width: 100%;

  font-weight: 600;
  text-align: center;

  &:disabled {
    opacity: 0.5;
  }
`;

function Button(props: Props, ref: React.Ref<typeof StyledButton>) {
  return (
    <StyledButton
      ref={ref}
      transition={{ duration: 0.2 }}
      whileTap={{ scale: 0.9 }}
      {...props}
      size={props.size ?? "medium"}
      variant={props.variant ?? "primary"}
    />
  );
}

export default React.forwardRef(Button);
