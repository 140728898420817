import HomeSection from "../../components/HomeSection";
import Heading1 from "../../components/Heading1";
import styled from "styled-components";
import CaregiverQuestionCard from "./CaregiverQuestionCard";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getQuestions } from "./CaregiverQuestionsData";

const CaregiversAskSection = () => {
  const { t } = useTranslation();
  const caregiverQuestions = getQuestions(t);

  const [showMore, setShowMore] = useState(false);

  const questionsToShow = !showMore ? caregiverQuestions.slice(0, 4) : caregiverQuestions;

  return (
    <Container align="center" backgroundColor="#EFF3FB">
      <Heading>{t("home-caregivers-ask-title")}</Heading>
      {questionsToShow.map((question, i) => (
        <CaregiverQuestionCard key={i} question={question} />
      ))}
      {!showMore ? (
        <ShowAllQuestions onClick={() => setShowMore(!showMore)}>
          {t("home-caregivers-ask-show-more")}
        </ShowAllQuestions>
      ) : null}
    </Container>
  );
};

const Container = styled(HomeSection)`
  padding: 40px 16px 16px 16px;
`;

const Heading = styled(Heading1)`
  margin-bottom: 16px;
`;

const ShowAllQuestions = styled.div`
  padding: 12px 16px;

  font-size: 14px;
  font-weight: 900;
  line-height: 140%;
  color: ${(props) => props.theme.colors.palette.blue["500"]};
  text-align: center;
`;

export default CaregiversAskSection;
