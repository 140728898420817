import { ReactComponent as StarSvg } from "/public/star.svg";
import { ReactComponent as StarEmptySvg } from "/public/star-empty.svg";
import { ReactComponent as StarSmallSvg } from "/public/star-small.svg";
import { ReactComponent as StarSmallEmptySvg } from "/public/star-small-empty.svg";

export interface StarProps {
  size: "small" | "big";
  appearance: "fill" | "outline";
}

const starDesign = {
  big: {
    fill: <StarSvg />,
    outline: <StarEmptySvg />,
  },
  small: {
    fill: <StarSmallSvg />,
    outline: <StarSmallEmptySvg />,
  },
};

const Star = (props: StarProps) => {
  return starDesign[props.size][props.appearance];
};

export default Star;
