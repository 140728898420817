import React from "react";
import styled from "styled-components";

interface Props {
  src: string;
  onClick?: () => void;
}

const StyledIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
`;

const Icon = styled.img`
  max-width: 14px;
  max-height: 14px;
`;

const IconButton = React.forwardRef((props: Props, ref: React.Ref<HTMLButtonElement>) => {
  return (
    <StyledIconButton onClick={props.onClick} ref={ref}>
      <Icon src={props.src} alt="Icon" />
    </StyledIconButton>
  );
});

IconButton.displayName = "IconButton";

export default Object.assign(IconButton, { Styled: StyledIconButton });
