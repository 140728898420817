import styled, { keyframes } from "styled-components";

interface Props {
  text: string;
  size: number;
  delay: number;
  duration: number;
  left: number;
  bottom: number;
}

const EventBubble = (props: Props) => (
  <EventBubble.Styled {...props}>{props.text}</EventBubble.Styled>
);

const riseBubble = (props: Props) => keyframes`
  0% {
    bottom: ${props.bottom}%;

    opacity: 1;
  }
  50% {
    bottom: ${props.bottom + 100}%;

    opacity: 0;
  }
  51% {
    bottom: ${props.bottom - 100}%;

    opacity: 0;
  }
  100% {
    bottom: ${props.bottom}%;

    opacity: 1;
  }
`;

const slideBubble = keyframes`
  0% {
    margin-left:0px;
  }
  100% {
    margin-left: 20px;
  }
`;

EventBubble.Styled = styled.div<Props>`
  --size: ${(props) => props.size}px;

  position: absolute;
  bottom: ${(props) => props.bottom}%;
  left: ${(props) => props.left}%;

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--size);
  height: var(--size);

  font-size: calc(var(--size) * 0.4);
  font-weight: 700;
  color: ${(props) => props.theme.colors.static.neutral};

  background: ${(props) => props.theme.colors.palette.blue["100"]};
  border-radius: var(--size);

  animation: ${(props) => riseBubble(props)} ${(props) => props.duration / 2}s linear infinite,
    ${slideBubble} 6s ease-in-out infinite alternate;
`;

export default EventBubble;
