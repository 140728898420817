import styled from "styled-components";
import { motion } from "framer-motion";

const MapImage = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  opacity: 0.5;
  background-image: url("/map.jpg");
  background-position: center;
  background-size: cover;
`;

export default MapImage;
