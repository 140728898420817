import React from "react";
import { mergeRefs } from "react-merge-refs";
import styled, { css } from "styled-components";

interface Props extends React.AriaAttributes {
  inputProps: Omit<JSX.IntrinsicElements["input"], "value"> & { value: string };
}

const SMSCodeInput = (props: Props) => {
  const innerInputRef = React.useRef<HTMLInputElement>(null);

  const inputRef =
    props.inputProps.ref !== undefined
      ? mergeRefs([props.inputProps.ref, innerInputRef])
      : innerInputRef;

  const handleClick = () => {
    focusActualInputOnWeb();
  };

  const focusActualInputOnWeb = React.useCallback(() => {
    if (innerInputRef.current !== null) {
      innerInputRef.current.focus();
      innerInputRef.current.setSelectionRange(
        innerInputRef.current.value.length,
        innerInputRef.current.value.length
      );
    }
  }, []);

  React.useEffect(() => {
    focusActualInputOnWeb();
  }, [focusActualInputOnWeb]);

  const { ...rest } = props;

  return (
    <SMSCodeInput.Root onClick={handleClick} {...rest}>
      {[0, 1, 2, 3, 4].map((i) => (
        <SMSCodeInput.Box
          key={i}
          aria-invalid={props["aria-invalid"]}
          filled={props.inputProps.value.length > i}
        >
          {props.inputProps.value[i]}
        </SMSCodeInput.Box>
      ))}
      <SMSCodeInput.Input {...props.inputProps} ref={inputRef} />
    </SMSCodeInput.Root>
  );
};

SMSCodeInput.Input = styled.input`
  position: absolute;

  width: 0;
  height: 0;

  opacity: 0;
`;
SMSCodeInput.Box = styled.div<{ filled: boolean }>`
  --size: 12vw;
  --max-size: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--size);
  max-width: var(--max-size);
  height: var(--size);
  max-height: var(--max-size);

  font-size: 16px;
  font-weight: 700;
  text-align: center;

  border: 2px solid lightgrey;
  border-radius: 8px;

  transition: all 250ms ease;

  &[aria-invalid="true"] {
    border-color: ${(props) => props.theme.colors.negative()};
  }

  ${(props) =>
    props.filled
      ? css`
          transform: translateY(-15%);

          border-color: ${(props) => props.theme.colors.primary()};
        `
      : css``}
`;

SMSCodeInput.Root = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
`;

export default SMSCodeInput;
