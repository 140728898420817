import HomeSection from "../../components/HomeSection";
import Heading1 from "../../components/Heading1";
import AppRating from "./AppRating";
import CaregiverReviewsCarousel from "./CaregiverReviewsCarousel/CaregiverReviewsCarousel";
import { useTranslation } from "react-i18next";

function CaregiversReviewsSection() {
  const { t } = useTranslation();

  return (
    <HomeSection>
      <Heading1>{t("home-caregiver-reviews-title")}</Heading1>
      <AppRating />
      <CaregiverReviewsCarousel />
    </HomeSection>
  );
}

export default CaregiversReviewsSection;
