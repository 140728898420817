import styled from "styled-components";

interface Props {
  imageUrl: string;
  size: number;
}

const Avatar = styled.div`
  --size: ${(props: Props) => props.size}px;

  width: var(--size);
  height: var(--size);

  background-image: url(${(props: Props) => props.imageUrl});
  background-position: center center;
  background-clip: padding-box;
  background-size: cover;
  border-radius: var(--size);
`;

export default Avatar;
