import theme from "@shared/theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import HomeRoute from "./routes/Home/HomeRoute";
import NotCertifiedRoute from "./routes/OnboardingRoute/NotCertified/NotCertifiedRoute";
import RegionUnavailableRoute from "./routes/OnboardingRoute/RegionUnavailable/RegionUnavailableRoute";
import OnboardingRoute from "./routes/OnboardingRoute/OnboardingRoute";
import CampaignRoute from "./routes/Campaign/CampaignRoute";
import ReviewRoute from "./routes/Review/ReviewRoute";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route index element={<HomeRoute />} />
          <Route path="onboarding">
            <Route path="not-certified" element={<NotCertifiedRoute />} />
            <Route path="region-unavailable" element={<RegionUnavailableRoute />} />
            <Route index element={<OnboardingRoute />} />
          </Route>
          <Route path="campaign/:campaign" element={<CampaignRoute />} />
          <Route path="review" element={<ReviewRoute />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
